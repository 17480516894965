import React, { memo } from "react";
import { BaseLoader } from "blace-frontend-library";
import cn from "classnames";
import { BaseIcon } from "@/src/component/base";
import { PhotoLogic } from "@/src/model";
import { RoomPhotoFile } from "@/src/type/app";
import styles from "./RoomPhoto.module.scss";

interface PhotoProps {
  photo: RoomPhotoFile;
  isSelected: boolean;
  index: number;
  handleDeleteIconClick: (imageHash?: string) => void;
  handlePhotoSelection: (isSelected: boolean, photo: RoomPhotoFile) => void;
}

const Photo = memo(
  ({
    photo,
    index,
    isSelected,
    handleDeleteIconClick,
    handlePhotoSelection,
  }: PhotoProps) => {
    const fileImageUrl = PhotoLogic.getFileImageUrl(photo);

    return (
      <div
        key={photo.imageHash}
        className={cn(styles.photoWrapper, {
          [styles.selected]: isSelected,
        })}
      >
        <img
          src={fileImageUrl}
          alt={`photo ${index + 1}`}
          className={cn(styles.photo, {
            [styles.isLoading]: photo.loading,
          })}
        />
        {photo.loading && (
          <BaseLoader
            size={16}
            color="inherit"
            wrapperClassName={styles.imageLoaderWrapper}
          />
        )}
        {!!photo.file && (
          <div
            className={styles.trashIconContainer}
            onClick={() => handleDeleteIconClick(photo.imageHash)}
          >
            <BaseIcon
              iconFileName="trashIcon"
              iconAlt="remove icon"
              iconSize={24}
            />
          </div>
        )}
        <div
          className={cn(styles.checkboxWrapper, {
            [styles.isSelected]: isSelected,
          })}
        >
          <input
            type="checkbox"
            checked={isSelected}
            onChange={(e) => handlePhotoSelection(e.target.checked, photo)}
          />
        </div>
      </div>
    );
  }
);

Photo.displayName = "Photo";

export default Photo;
