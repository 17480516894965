import { useCallback, useContext, useEffect, useState } from "react";
import type { Dispatch, SetStateAction } from "react";
import { Typography } from "@mui/material";
import { Log, StringHelper } from "blace-frontend-library";
import { FileUpload } from "@/src/component/base";
import {
  FileUploadErrors,
  UploaderInfo,
} from "@/src/component/base/FileUpload/FileUpload";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { useListingItemFilesManager } from "@/src/hook";
import { AzureBlobService, B2BSearchServiceV2 } from "@/src/service";
import { ListingItemFile, ListingItemFileTypes } from "@/src/type/app";
import styles from "./FileUploadSection.module.scss";

interface FileUploadSectionProps {
  listingItemFiles: ListingItemFile[];
  setListingItemFiles: (files: ListingItemFile[]) => void;
  setAreFilesBlocked: Dispatch<SetStateAction<boolean>>;
}

function FileUploadSection({
  listingItemFiles,
  setListingItemFiles,
  setAreFilesBlocked,
}: FileUploadSectionProps) {
  // Floor Plan
  const [fileFloorPlanName, setFileFloorPlanName] = useState<string>("");
  const [fileFloorPlanSize, setFileFloorPlanSize] = useState<number>(0);
  const [isFileFloorPlanLoading, setIsFileFloorPlanLoading] =
    useState<boolean>(false);
  const [isMaxSizeFileFloorPlanLimited, setIsMaxSizeFileFloorPlanLimited] =
    useState<boolean>(false);
  const [fileUploadFloorPlanError, setFileUploadFloorPlanError] =
    useState<string>("");
  const [uploadedFloorPlanFile, setUploadedFloorPlanFile] =
    useState<FileList | null>(null);

  // Tear Sheet
  const [fileTearSheetName, setFileTearSheetName] = useState<string>("");
  const [fileTearSheetSize, setFileTearSheetSize] = useState<number>(0);
  const [isFileTearSheetLoading, setIsFileTearSheetLoading] =
    useState<boolean>(false);
  const [isMaxSizeFileTearSheetLimited, setIsMaxSizeFileTearSheetLimited] =
    useState<boolean>(false);
  const [fileUploadTearSheetError, setFileUploadTearSheetError] =
    useState<string>("");
  const [uploadedTearSheetFile, setUploadedTearSheetFile] =
    useState<FileList | null>(null);

  // general
  const listingManagementContext = useContext(ListingManagementContext);
  const listingItemFilesManager = useListingItemFilesManager();

  const limitFileSizeMb = 20;
  const attachmentContentType = "application/pdf";

  async function fileUploadFloorPlanHandler(data: FileList) {
    resetFloorPlanErrors();

    setUploadedFloorPlanFile(data);
    const file = data[0];

    if (!file || !listingManagementContext?.requestSearchId) {
      setFileUploadFloorPlanError(FileUploadErrors.UploadFailed);

      return;
    }

    const { type } = file;
    if (type !== attachmentContentType) {
      setFileUploadFloorPlanError(FileUploadErrors.NonPDFFormat);

      return;
    }

    const fileName = file.name;
    const fileSize = file.size;
    const fileSizeMb = fileSize / (1024 * 1024);

    if (fileSizeMb > limitFileSizeMb) {
      setIsMaxSizeFileFloorPlanLimited(true);

      return;
    }

    setIsFileFloorPlanLoading(true);

    try {
      const linkFileUploadResponse =
        await B2BSearchServiceV2.getSearchItemFileUploadLink(
          listingManagementContext?.requestSearchId,
          fileName,
          ListingItemFileTypes.FloorPlan,
          attachmentContentType,
          fileSize
        );

      if (!linkFileUploadResponse?.success) {
        throw new Error(
          `Failed to receive an upload link for Floor Plan (${fileName} - ${fileSize}) of Search item (${listingManagementContext?.requestSearchId}).`
        );
      }

      const { fileNameCanonical, link, blobName } =
        linkFileUploadResponse?.body?.payload;

      const putFileToServerResponse = await AzureBlobService.putFileToServer(
        link,
        fileNameCanonical,
        file
      );

      //@ts-ignore
      if (!putFileToServerResponse.ok) {
        throw new Error(
          `Failed to upload Floor Plan (${fileName} - ${fileSize}) of Search item (${listingManagementContext?.requestSearchId}).`
        );
      }

      listingItemFilesManager.setNewFile(
        listingItemFiles ?? [],
        setListingItemFiles,
        {
          name: fileName,
          fileNameCanonical,
          type: ListingItemFileTypes.FloorPlan,
          contentType: type,
          size: fileSize,
          blobName,
        }
      );
    } catch (e) {
      setFileUploadFloorPlanError(FileUploadErrors.UploadFailed);

      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "FileUploadSection.tsx",
        "upload Floor Plan Attachment Error",
        [e]
      );
    }

    setIsFileFloorPlanLoading(false);
  }

  async function fileUploadTearSheetHandler(data: FileList) {
    resetTearSheetErrors();

    setUploadedTearSheetFile(data);
    const file = data[0];

    if (!file || !listingManagementContext?.requestSearchId) {
      setFileUploadTearSheetError(FileUploadErrors.UploadFailed);

      return;
    }

    const { type } = file;
    if (type !== attachmentContentType) {
      setFileUploadTearSheetError(FileUploadErrors.NonPDFFormat);

      return;
    }

    const fileName = file.name;
    const fileSize = file.size;
    const fileSizeMb = fileSize / (1024 * 1024);

    if (fileSizeMb > limitFileSizeMb) {
      setIsMaxSizeFileTearSheetLimited(true);

      return;
    }

    setIsFileTearSheetLoading(true);

    try {
      const linkFileUploadResponse =
        await B2BSearchServiceV2.getSearchItemFileUploadLink(
          listingManagementContext?.requestSearchId,
          fileName,
          ListingItemFileTypes.TearSheet,
          attachmentContentType,
          fileSize
        );

      if (!linkFileUploadResponse?.success) {
        throw new Error(
          `Failed to receive an upload link for Tear Sheet (${fileName} - ${fileSize}) of Search item (${listingManagementContext?.requestSearchId}).`
        );
      }

      const { fileNameCanonical, link, blobName } =
        linkFileUploadResponse?.body?.payload;

      const putFileToServerResponse = await AzureBlobService.putFileToServer(
        link,
        fileNameCanonical,
        file
      );

      //@ts-ignore
      if (!putFileToServerResponse.ok) {
        throw new Error(
          `Failed to upload Tear Sheet (${fileName} - ${fileSize}) of Search item (${listingManagementContext?.requestSearchId}).`
        );
      }

      listingItemFilesManager.setNewFile(
        listingItemFiles ?? [],
        setListingItemFiles,
        {
          name: fileName,
          fileNameCanonical,
          type: ListingItemFileTypes.TearSheet,
          contentType: type,
          size: fileSize,
          blobName,
        }
      );
    } catch (e) {
      setFileUploadTearSheetError(FileUploadErrors.UploadFailed);

      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "FileUploadSection.tsx",
        "upload Tear Sheet Attachment Error",
        [e]
      );
    }

    setIsFileTearSheetLoading(false);
  }

  const resetFloorPlanErrors = useCallback((): void => {
    setIsMaxSizeFileFloorPlanLimited(false);
    setFileUploadFloorPlanError("");
    setIsFileFloorPlanLoading(false);
  }, [
    setIsMaxSizeFileFloorPlanLimited,
    setFileUploadFloorPlanError,
    setIsFileFloorPlanLoading,
  ]);

  const resetTearSheetErrors = useCallback((): void => {
    setIsMaxSizeFileTearSheetLimited(false);
    setFileUploadTearSheetError("");
    setIsFileTearSheetLoading(false);
  }, [
    setIsMaxSizeFileTearSheetLimited,
    setFileUploadTearSheetError,
    setIsFileTearSheetLoading,
  ]);

  const resetFloorPlanInfo = useCallback((): void => {
    resetFloorPlanErrors();

    setFileFloorPlanName("");
    setFileFloorPlanSize(0);

    setUploadedFloorPlanFile(null);
  }, [
    resetFloorPlanErrors,
    setFileFloorPlanName,
    setFileFloorPlanSize,
    setUploadedFloorPlanFile,
  ]);

  const resetTearSheetInfo = useCallback((): void => {
    resetTearSheetErrors();

    setFileTearSheetName("");
    setFileTearSheetSize(0);

    setUploadedTearSheetFile(null);
  }, [
    resetTearSheetErrors,
    setFileTearSheetName,
    setFileTearSheetSize,
    setUploadedTearSheetFile,
  ]);

  const openInNewTabFloorPlanHandler = useCallback((): void => {
    // existing file
    const floorPlanFile = listingItemFilesManager?.getFile(
      listingItemFiles ?? [],
      ListingItemFileTypes.FloorPlan
    );
    if (floorPlanFile && floorPlanFile.url) {
      window.open(floorPlanFile.url, "_blank");

      return;
    }

    // uploaded file
    const uploadedPdf = uploadedFloorPlanFile;
    const uploadedFile = uploadedPdf && uploadedPdf[0];

    if (uploadedFile) {
      const blobData = new Blob([uploadedFile], { type: uploadedFile.type });
      const blobUrl = URL.createObjectURL(blobData);
      window.open(blobUrl, "_blank");
    }
  }, [uploadedFloorPlanFile, listingItemFiles, listingItemFilesManager]);

  const openInNewTabTearSheetHandler = useCallback(() => {
    // existing file
    const tearSheetFile = listingItemFilesManager?.getFile(
      listingItemFiles ?? [],
      ListingItemFileTypes.TearSheet
    );
    if (tearSheetFile && tearSheetFile.url) {
      window.open(tearSheetFile.url, "_blank");

      return;
    }

    // uploaded file
    const uploadedPdf = uploadedTearSheetFile;
    const uploadedFile = uploadedPdf && uploadedPdf[0];

    if (uploadedFile) {
      const blobData = new Blob([uploadedFile], { type: uploadedFile.type });
      const blobUrl = URL.createObjectURL(blobData);
      window.open(blobUrl, "_blank");
    }
  }, [uploadedTearSheetFile, listingItemFiles, listingItemFilesManager]);

  const removeFileFloorPlanHandler = useCallback(() => {
    listingItemFilesManager.setNewFile(
      listingItemFiles ?? [],
      setListingItemFiles,
      null,
      ListingItemFileTypes.FloorPlan
    );

    resetFloorPlanInfo();
  }, [
    listingItemFiles,
    setListingItemFiles,
    listingItemFilesManager,
    resetFloorPlanInfo,
  ]);

  const removeFileTearSheetHandler = useCallback(() => {
    listingItemFilesManager.setNewFile(
      listingItemFiles ?? [],
      setListingItemFiles,
      null,
      ListingItemFileTypes.TearSheet
    );

    resetTearSheetInfo();
  }, [
    listingItemFiles,
    setListingItemFiles,
    listingItemFilesManager,
    resetTearSheetInfo,
  ]);

  const checkAbilityToUploadFile = () => {
    if (!listingManagementContext?.listingItemData?.searchId) {
      listingManagementContext?.setListingItemWarningText(
        "Please add and save a listing name before uploading"
      );
      return false;
    }
    return true;
  };

  // init files and replace them after save operation
  useEffect(() => {
    // existing file
    const floorPlanFile = listingItemFilesManager?.getFile(
      listingItemFiles ?? [],
      ListingItemFileTypes.FloorPlan
    );
    if (floorPlanFile) {
      setFileFloorPlanName(floorPlanFile.name);
      const formattedFileSize = StringHelper.formatFileSize(floorPlanFile.size);
      setFileFloorPlanSize(formattedFileSize);

      // if saved Floor Plan exists - remove uploaded one
      if (floorPlanFile.url && uploadedFloorPlanFile) {
        setUploadedFloorPlanFile(null);
      }

      return;
    }

    // uploaded file
    if (uploadedFloorPlanFile) {
      const file = uploadedFloorPlanFile[0];

      if (file) {
        setFileFloorPlanName(file.name);
        const formattedFileSize = StringHelper.formatFileSize(file.size);
        setFileFloorPlanSize(formattedFileSize);
      }
    }
  }, [uploadedFloorPlanFile, listingItemFiles, listingItemFilesManager]);

  useEffect(() => {
    // existing file
    const tearSheetFile = listingItemFilesManager?.getFile(
      listingItemFiles ?? [],
      ListingItemFileTypes.TearSheet
    );
    if (tearSheetFile) {
      setFileTearSheetName(tearSheetFile.name);
      const formattedFileSize = StringHelper.formatFileSize(tearSheetFile.size);
      setFileTearSheetSize(formattedFileSize);

      // if saved Tear Sheet exists - remove uploaded one
      if (tearSheetFile.url && uploadedTearSheetFile) {
        setUploadedTearSheetFile(null);
      }

      return;
    }

    // uploaded file
    if (uploadedTearSheetFile) {
      const file = uploadedTearSheetFile[0];

      if (file) {
        setFileTearSheetName(file.name);
        const formattedFileSize = StringHelper.formatFileSize(file.size);
        setFileTearSheetSize(formattedFileSize);
      }
    }
  }, [uploadedTearSheetFile, listingItemFiles, listingItemFilesManager]);

  // pass the files blocked state
  useEffect(() => {
    setAreFilesBlocked(
      isFileFloorPlanLoading ||
        isMaxSizeFileFloorPlanLimited ||
        Boolean(fileUploadFloorPlanError) ||
        isFileTearSheetLoading ||
        isMaxSizeFileTearSheetLimited ||
        Boolean(fileUploadTearSheetError)
    );
  }, [
    setAreFilesBlocked,
    isFileFloorPlanLoading,
    isMaxSizeFileFloorPlanLimited,
    fileUploadFloorPlanError,
    isFileTearSheetLoading,
    isMaxSizeFileTearSheetLimited,
    fileUploadTearSheetError,
  ]);

  return (
    <>
      <div className={styles.fileUploadWrapper}>
        {fileFloorPlanName && (
          <Typography className={styles.fileLabel}>Floor plan</Typography>
        )}
        <FileUpload
          fileType={UploaderInfo.FloorPlan}
          fileName={fileFloorPlanName}
          fileSize={fileFloorPlanSize}
          isFileLoading={isFileFloorPlanLoading}
          isMaxSizeFileLimited={isMaxSizeFileFloorPlanLimited}
          fileUploadError={fileUploadFloorPlanError}
          setUploadFileData={fileUploadFloorPlanHandler}
          openInNewTabHandler={openInNewTabFloorPlanHandler}
          removeFileHandler={removeFileFloorPlanHandler}
          canOpenFileUploader={checkAbilityToUploadFile}
        />
      </div>
      <div className={styles.fileUploadWrapper}>
        {fileTearSheetName && (
          <Typography className={styles.fileLabel}>Tear sheet</Typography>
        )}
        <FileUpload
          fileType={UploaderInfo.TearSheet}
          fileName={fileTearSheetName}
          fileSize={fileTearSheetSize}
          isFileLoading={isFileTearSheetLoading}
          isMaxSizeFileLimited={isMaxSizeFileTearSheetLimited}
          fileUploadError={fileUploadTearSheetError}
          setUploadFileData={fileUploadTearSheetHandler}
          openInNewTabHandler={openInNewTabTearSheetHandler}
          removeFileHandler={removeFileTearSheetHandler}
          canOpenFileUploader={checkAbilityToUploadFile}
        />
      </div>
    </>
  );
}

export default FileUploadSection;
