import { NumberHelper } from "blace-frontend-library";
import { FormikProps } from "formik";
import { DEFAULT_FORM_FIELD_BE_ERROR, NUMBER_FIELD_REG_EXP } from "@/src/const";

export function setFormikCleanedNumberValue<T>(
  value: string,
  formik: FormikProps<T>,
  fieldName: string
) {
  if (NUMBER_FIELD_REG_EXP.test(value) || !value) {
    const clearedValue = NumberHelper.clearInputValue(value);
    formik.setFieldValue(fieldName, clearedValue ?? "");
  }
}

export function getFormikCleanedNumberValue(value: string) {
  if (NUMBER_FIELD_REG_EXP.test(value) || !value) {
    const clearedValue = NumberHelper.clearInputValue(value);
    return clearedValue;
  }
}

export function handleServerErrors<TValues extends Record<string, any>>(
  errors: Record<string, any>,
  values: TValues,
  setFieldError: (field: string, message: string) => void,
  unknownFields: string[]
) {
  for (const field in errors) {
    if (field in values) {
      setFieldError(field, DEFAULT_FORM_FIELD_BE_ERROR);
    } else if (typeof errors[field] == "object") {
      // todo: add recursion if we'll need deeper sub-items
      for (const subField in errors[field]) {
        if (subField in values) {
          setFieldError(subField, DEFAULT_FORM_FIELD_BE_ERROR);
        } else {
          unknownFields.push(field + "." + subField);
        }
      }
    } else {
      unknownFields.push(field);
    }
  }
}
