import { useContext } from "react";
import { Typography } from "@mui/material";
import { BaseButton } from "blace-frontend-library";
import cn from "classnames";
import { BaseIcon } from "@/src/component/base";
import { CategoryItem } from "@/src/component/view/ListingManagement/ListingManagement";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import styles from "./LeftSidebar.module.scss";
import { CategoriesList } from "./components/CategoriesList";
import { StatusIcon } from "./components/StatusIcon";

interface LeftSidebarProps {
  categoriesList: CategoryItem[];
  goBackHandler: () => void;
  onPublishHandler: () => void;
  isPublishButtonDisabled: boolean;
}

function LeftSidebar({
  categoriesList,
  isPublishButtonDisabled,
  goBackHandler,
  onPublishHandler,
}: LeftSidebarProps) {
  const listingManagementContext = useContext(ListingManagementContext);

  return (
    <div className={styles.leftSidebar}>
      <BaseButton className={styles.backButton} onClick={goBackHandler}>
        <BaseIcon
          iconFileName="arrowLeftIcon"
          iconAlt="go back event icon"
          iconSize={20}
          className={styles.backButtonIcon}
        />
        Back to my Listings
      </BaseButton>
      <Typography className={styles.listingTitle}>
        {listingManagementContext?.listingItemData?.title}
      </Typography>
      {listingManagementContext?.listingItemMainImageUrl && (
        <div className={styles.coverImage}>
          <img
            src={listingManagementContext.listingItemMainImageUrl}
            alt="cover image"
          />
        </div>
      )}
      <StatusIcon status={listingManagementContext?.listingItemData?.status} />
      <CategoriesList categoriesList={categoriesList} />
      <BaseButton className={styles.priviewButton}>
        <BaseIcon
          iconFileName="previewIcon"
          iconAlt="preview icon"
          iconSize={20}
          className={styles.previewIcon}
        />
        Preview
      </BaseButton>
      <BaseButton className={styles.saveDraftButton}>
        <BaseIcon
          iconFileName="saveDraftIcon"
          iconAlt="save as a draft icon"
          iconSize={20}
          className={styles.saveDraftIcon}
        />
        Save as a Draft
      </BaseButton>
      <BaseButton
        onClick={onPublishHandler}
        className={cn(styles.publishButton, {
          [styles.isDisabled]: isPublishButtonDisabled,
        })}
        disabled={isPublishButtonDisabled}
      >
        <BaseIcon
          iconFileName="publishIcon"
          iconAlt="publish icon"
          iconSize={20}
        />
        Publish
      </BaseButton>
    </div>
  );
}

export default LeftSidebar;
