import React from "react";
import { KEYS, SharedConfigManager } from "blace-frontend-library";

interface BaseIconProps {
  iconFileName: string;
  iconAlt: string;
  iconSize?: number;
  className?: string;
  key?: string;
}

function BaseIcon({ iconFileName, iconAlt, iconSize = 16, className, key }: BaseIconProps) {
  const SVG_URL: string = SharedConfigManager.getValue(KEYS.TEXT_CDN_FRONTEND);

  return (
    <img
      src={`${SVG_URL}/${iconFileName}.svg`}
      alt={iconAlt}
      width={iconSize}
      height={iconSize}
      className={className}
      key={key ?? iconFileName}
    />
  );
}

export default BaseIcon;
