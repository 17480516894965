import { useCallback, useContext } from "react";
import { ListingCategories } from "@/src/component/view/ListingManagement/ListingManagement";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { DetailsContent } from "./DetailsContent";
import { PhotosContent } from "./PhotosContent";
import { RoomsContent } from "./RoomsContent";

interface MainSectionContentProps {}

function MainSectionContent({}: MainSectionContentProps) {
  const listingManagementContext = useContext(ListingManagementContext);

  const renderSelectedCategoryMainSection = useCallback(() => {
    switch (listingManagementContext?.categorySelected) {
      case ListingCategories.Details:
        return <DetailsContent />;
      case ListingCategories.Photos:
        return <PhotosContent />;
      case ListingCategories.Rooms:
        return <RoomsContent />;
      default:
        return <></>;
    }
  }, [listingManagementContext?.categorySelected]);

  return <>{renderSelectedCategoryMainSection()}</>;
}

export default MainSectionContent;
