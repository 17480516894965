import { ActivityLogic } from "blace-frontend-library";
import { subDays } from "date-fns";
import {
    FEATURE_INQUIRY_NEEDS_UPDATE_LAUNCH_DATE,
    INQUIRY_DONE_STATUSES,
    INQUIRY_NEEDS_UPDATE_THRESHOLD_DAYS,
} from "@/src/const";
import { B2BInquiryServiceV2 } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { InquirySearchType } from "@/src/type/blaceV2";
import { DateHelper } from "@/src/util/helper/DateHelper";

export function isInquiryNeedsUpdate(inquiry?: InquirySearchType): boolean {
  if (!inquiry) {
    return false;
  }
  if (INQUIRY_DONE_STATUSES.includes(inquiry.inquiryStatus)) {
    return false;
  }
  const inquiryCreateData = new Date(inquiry.createDate);
  if (!Number.isNaN(inquiryCreateData.getTime())
      && inquiryCreateData.getTime() < FEATURE_INQUIRY_NEEDS_UPDATE_LAUNCH_DATE.getTime()
  ) {
    return false;
  }

  return DateHelper.isDateLaterThanDaysAgo(
    inquiry.lastActivityDate ?? inquiry.updateDate,
    INQUIRY_NEEDS_UPDATE_THRESHOLD_DAYS
  );
}

export async function getNeedsUpdateInquiriesNumber() {
  const needsUpdateInquiriesDate = subDays(
    new Date(),
    INQUIRY_NEEDS_UPDATE_THRESHOLD_DAYS
  );
  const delimiter = "|";
  const filterList = [
      `not search.in(inquiryStatus,'${INQUIRY_DONE_STATUSES.join(delimiter)}','${delimiter}')`,
      `lastActivityDate le ${needsUpdateInquiriesDate.getTime()}`,
      `createDate ge ${FEATURE_INQUIRY_NEEDS_UPDATE_LAUNCH_DATE.getTime()}`,
  ];

  const queryToGetNeedUpdatesInquiries: BlaceV2Type.AzureSearchQueryType.Request =
    {
      count: true,
      search: "",
      queryType: "full",
      searchMode: "all",
      select: "inquiryId",
      searchFields: "",
      top: 1,
      orderby: "lastActivityDate desc",
      sessionId: await ActivityLogic.SESSION_ID(),
      filter: filterList.join(" and "),
    };

  const needUpdatesInquiriesResponse =
    await B2BInquiryServiceV2.postB2BInquiryQuery(
      queryToGetNeedUpdatesInquiries
    );

  if ((needUpdatesInquiriesResponse.body?.payload?.value ?? []).length > 0) {
    return needUpdatesInquiriesResponse.body?.payload["@odata.count"] ?? 0;
  }

  return 0;
}
