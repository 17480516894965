import React, { useContext, useEffect, useRef, useState } from "react";
import { BaseButton, useBreakPointUp } from "blace-frontend-library";
import { BaseIcon } from "@/src/component/base";
import { ListingCategories } from "@/src/component/view/ListingManagement/ListingManagement";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import HeadingSectionContent from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/HeadingSectionContent/HeadingSectionContent";
import { FormRef } from "@/src/type/app";
import { SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";
import { uniqueId } from "@/src/util";
import RoomContentContext from "./RoomContentContext";
import styles from "./RoomsContent.module.scss";
import { EntryPopup } from "./component/EntryPopup";
import RoomFormManager from "./component/RoomFormManager/RoomFormManager";
import RoomListContainer from "./component/RoomListContainer/RoomListContainer";

const emptyRoom = {
  name: undefined,
  images: [],
};

function RoomsContent() {
  const [rooms, setRooms] = useState<Partial<SearchRoomV2>[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<
    Partial<SearchRoomV2> | undefined
  >(undefined);

  const isDesktop = useBreakPointUp("md");
  const [isEntryPopupVisible, setIsEntryPopupVisible] = useState(false);

  const { listingItemData } = useContext(ListingManagementContext) || {};

  const formRef = useRef<FormRef>(null);

  const onSaveRoomFormData = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const handleAddNewRoom = () => {
    const newRoom = { ...emptyRoom, id: uniqueId() };
    setRooms([...rooms, newRoom]);
  };

  useEffect(() => {
    if (!Array.isArray(listingItemData?.rooms)) {
      setIsEntryPopupVisible(true);
    } else {
      setIsEntryPopupVisible(false);
    }
  }, [listingItemData]);

  //setting fetched rooms or if no rooms add default empty room
  //when listingDataItem is refetched set refetched room data to selected room
  useEffect(() => {
    const listingRooms = listingItemData?.rooms || [];
    if (listingRooms.length) {
      setRooms(listingRooms);
    } else {
      const newRoom = { ...emptyRoom, id: uniqueId() };
      setRooms([newRoom]);
    }

    if (selectedRoom) {
      const savedSelectedRoomData = listingItemData?.rooms?.find(
        (room) => room.id === selectedRoom.id
      );
      setSelectedRoom(savedSelectedRoomData);
    }

    // can't set selectedRoom to dependencies, it will cause infinite rerenders
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingItemData?.rooms]);

  //select first room from the list on initial load on desktop
  useEffect(() => {
    if (!selectedRoom && isDesktop) {
      setSelectedRoom(rooms[0]);
    }
  }, [rooms, selectedRoom, isDesktop]);

  return (
    <RoomContentContext.RoomContentContext.Provider
      value={{
        selectedRoom,
        setSelectedRoom,
        setRooms,
        allRooms: rooms,
      }}
    >
      <div className={styles.roomsWrapper} data-testid="rooms-content">
        <HeadingSectionContent
          onSaveDetailsFormData={onSaveRoomFormData}
          title={ListingCategories.Rooms}
        />
        <div className={styles.contentSection}>
          <div className={styles.addButtonWrapper}>
            <BaseButton
              startIcon={
                <BaseIcon
                  iconFileName="plusIcon"
                  iconAlt="add new room"
                  iconSize={20}
                  className={styles.addButtonIcon}
                />
              }
              className={styles.uploadButton}
              onClick={handleAddNewRoom}
            >
              Add New Room
            </BaseButton>
          </div>
          <div className={styles.roomsWrapper}>
            <RoomListContainer rooms={rooms} />
            <RoomFormManager
              ref={formRef}
              onSaveRoomFormData={onSaveRoomFormData}
            />
          </div>
        </div>
      </div>
      <EntryPopup
        isOpen={isEntryPopupVisible}
        onClose={() => setIsEntryPopupVisible(false)}
      />
    </RoomContentContext.RoomContentContext.Provider>
  );
}

export default RoomsContent;
