import React from "react";
import cn from "classnames";
import styles from "./TextareaDescription.module.scss";

interface TextareaDescriptionProps {
  value: string;
  isError: boolean;
}

function TextareaDescription({ value, isError }: TextareaDescriptionProps) {
  // TODO : move them to the props
  const minDetailsDescriptionInputSymbolsAmount = 50;
  const maxDetailsDescriptionInputSymbolsAmount = 3000;

  if (value?.length === 0) {
    return <></>;
  }

  return (
    <div className={cn(styles.descriptionInfo, isError ? styles.isError : styles.isValid)}>
      {`${value?.length}/${value?.length < minDetailsDescriptionInputSymbolsAmount ? minDetailsDescriptionInputSymbolsAmount : maxDetailsDescriptionInputSymbolsAmount}`}
    </div>
  );
}

export default TextareaDescription;
