import React from "react";
import { Typography } from "@mui/material";
import { BaseButton, ImageLogic } from "blace-frontend-library";
import classNames from "classnames";
import { BaseIcon, NoImagePlaceholder } from "@/src/component/base";
import ReactContentContext from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/RoomContentContext";
import { ListingStatusLabel } from "@/src/component/view/Listings/component/ListingsTable/component/ListingStatusLabel";
import { ListingStatus } from "@/src/type/blaceV2";
import { SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";
import styles from "./RoomCardList.module.scss";

interface RoomCardListProps {
  rooms: Partial<SearchRoomV2>[];
}

function RoomCardList({ rooms }: RoomCardListProps) {
  const { setSelectedRoom } = ReactContentContext.useRoomContext();

  return (
    <div className={styles.roomsList}>
      {rooms.map((roomItem) => {
        const roomImg = ImageLogic.getImageUrl(roomItem.images?.[0], 80, 300);
        return (
          <div className={styles.roomItem} key={roomItem.id}>
            <div className={styles.roomNameWrapper}>
              {roomImg ? (
                <img
                  src={roomImg}
                  alt={`${roomItem.name} photo`}
                  className={styles.roomImage}
                />
              ) : (
                <NoImagePlaceholder placeholderText="Edit your room to add a cover photo" />
              )}
              <Typography className={styles.roomName}>
                {roomItem.name || "Room name"}
              </Typography>
            </div>
            <div className={styles.roomStatusWrapper}>
              <Typography className={styles.roomStatus}>STATUS</Typography>
              <div className={styles.statusLabel}>
                <ListingStatusLabel listingStatus={ListingStatus.UNPUBLISHED} />
              </div>
            </div>
            <div className={styles.roomActionButtons}>
              <BaseButton
                startIcon={
                  <BaseIcon
                    iconFileName="retryLightBlueIcon"
                    iconAlt="retry icon"
                    iconSize={20}
                  />
                }
                className={classNames(styles.actionButton, {
                  [styles.disabled]: true,
                })}
              >
                Publish
              </BaseButton>
              <BaseButton
                startIcon={
                  <BaseIcon
                    iconFileName="editIcon"
                    iconAlt="edit icon"
                    iconSize={20}
                  />
                }
                className={classNames(styles.actionButton, {
                  [styles.disabled]: true,
                })}
                onClick={() => setSelectedRoom(roomItem)}
              >
                Edit
              </BaseButton>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default RoomCardList;
