import React from "react";
import { Typography } from "@mui/material";
import { BaseButton, ImageLogic } from "blace-frontend-library";
import classNames from "classnames";
import { BaseIcon, NoImagePlaceholder } from "@/src/component/base";
import RoomContentContext from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/RoomContentContext";
import { ListingStatusLabel } from "@/src/component/view/Listings/component/ListingsTable/component/ListingStatusLabel";
import { ListingStatus } from "@/src/type/blaceV2";
import { SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";
import styles from "./RoomRowList.module.scss";

interface RoomRowListProps {
  rooms: Partial<SearchRoomV2>[];
}

function RoomRowList({ rooms }: RoomRowListProps) {
  const { selectedRoom, setSelectedRoom } = RoomContentContext.useRoomContext();

  const handleSelectRoom = (roomItem: Partial<SearchRoomV2>) => {
    setSelectedRoom(roomItem);
  };

  return (
    <div className={styles.roomsList}>
      {rooms.map((roomItem) => {
        const isSelected = selectedRoom?.id === roomItem.id;
        const roomImage = roomItem?.images?.[0];

        const mainImage = ImageLogic.getImageUrl(roomImage, 80, 300);
        const roomName = roomItem.name || "Room name";

        return (
          <div
            className={classNames(styles.roomItem, {
              [styles.selected]: isSelected,
            })}
            key={roomItem.id}
            onClick={() => handleSelectRoom(roomItem)}
          >
            <div className={styles.roomNameWrapper}>
              {mainImage ? (
                <img
                  src={mainImage}
                  alt={`${roomItem.name} photo`}
                  className={styles.roomImage}
                />
              ) : (
                <NoImagePlaceholder
                  isLightBackground={true}
                  placeholderText="Edit your room to add a cover photo"
                />
              )}
              <Typography className={styles.roomName}>{roomName}</Typography>
            </div>
            <ListingStatusLabel listingStatus={ListingStatus.UNPUBLISHED} />
            <BaseButton
              startIcon={
                <BaseIcon
                  iconFileName="trashLightBlueIcon"
                  iconAlt="trash icon"
                  iconSize={20}
                />
              }
              className={classNames(styles.deactivateButton, {
                [styles.disabled]: true,
              })}
            >
              Deactivate
            </BaseButton>
          </div>
        );
      })}
    </div>
  );
}

export default RoomRowList;
