import React from "react";
import { DialogContent, Typography } from "@mui/material";
import { BaseButton } from "blace-frontend-library";
import { BaseIcon } from "@/src/component/base/BaseIcon";
import styles from "./BaseSuccessModalContent.module.scss";

interface BaseSuccessModalContentProps {
  title: string;
  subtitle?: string;
  ctaText?: string;
  onCtaClick?: () => void;
}

function BaseSuccessModalContent({
  title,
  subtitle,
  ctaText,
  onCtaClick,
}: BaseSuccessModalContentProps) {
  return (
    <>
      <div className={styles.iconWrapper}>
        <BaseIcon
          iconAlt="checkmark icon"
          iconFileName="Done_ring_round"
          iconSize={24}
        />
      </div>
      <DialogContent>
        <Typography variant="h4" className={styles.title}>
          {title}
        </Typography>
        {subtitle && (
          <Typography className={styles.subtitle}>{subtitle}</Typography>
        )}
      </DialogContent>
      {ctaText && onCtaClick && (
        <BaseButton
          onClick={onCtaClick}
          color="primary"
          className={styles.ctaButton}
        >
          {ctaText}
        </BaseButton>
      )}
    </>
  );
}

export default BaseSuccessModalContent;
