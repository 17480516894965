import { useContext } from "react";
import { Typography } from "@mui/material";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { SaveButton } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/DetailsContent/components/SaveButton";
import styles from "./HeadingSectionContent.module.scss";

interface HeadingSectionContentProps {
  title: string;
  onSaveDetailsFormData?: () => void;
}

function HeadingSectionContent({
  title,
  onSaveDetailsFormData,
}: HeadingSectionContentProps) {
  const listingManagementContext = useContext(ListingManagementContext);

  return (
    <div className={styles.headingSection}>
      <Typography variant="h3" className={styles.title}>
        {title}
      </Typography>
      <SaveButton
        isSaveButtonLoading={Boolean(
          listingManagementContext?.isEditRequestSubmitting
        )}
        isSaveButtonDisabled={Boolean(
          listingManagementContext?.isSaveButtonDisabled
        )}
        onSaveDetailsFormData={onSaveDetailsFormData}
      />
    </div>
  );
}

export default HeadingSectionContent;
