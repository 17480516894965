export enum InquiryTypes {
  Venue = "venue",
  Vendor = "vendor",
}

export enum SearchDataTypes {
  Venue = InquiryTypes.Venue,
  Vendor = InquiryTypes.Vendor,
}

export enum DashboardMobileDialogContentTypes {
  RenderRightSidebarContainer = "renderRightSidebarContainer",
  RenderFAQContainer = "renderFAQContainer",
  RenderMobileDialogManager = "renderMobileDialogManager",
}

export enum InquiryFilters {
  Open = "open",
  Canceled = "canceled",
  Completed = "completed",
  EventValue = "eventValue",
  StartDateValue = "startDateValue",
  EndDateValue = "endDateValue",
  InquiryId = "inquiryId",
}

export enum InquirySecondaryStatusLabels {
  Due = "due",
  Overdue = "overdue",
  Processing = "processing",
}

export enum InquiryStatusLabels {
  Open = "open",
  Completed = "completed",
  Closed = "closed",
  Planning = "planning",
  Draft = "draft",
  Qualified = "qualified",
  Booked = "booked"
}

export enum InquiryLogTitles {
  inquirySubmitted = "Inquiry received",
  inquiryChanged = "Event details changed",
  inquiryClosed = "Inquiry closed",
  inquiryReopened = "Inquiry reopened",
  inquiryBooked = "Inquiry booked"
}

export enum InquiryLogSourseTypes {
  Client = "client",
  Bussiness = "bussiness",
}

export enum InquiryLogEventTypes {
  Submit = "inquiry_submitted",
  Change = "inquiry_changed",
  Email = "email",
  Closed = "inquiry_closed",
  Reopen = "inquiry_reopened",
  PaymentReqested = "payment_requested",
  PaymentMade = "payment_made",
  Booked = "inquiry_booked",
  Completed = "inquiry_completed",
  StatusCheck = "status_check",
  SatisfactionCheck = "satisfaction_check"
}

export enum ChangedShortProperties {
  StartDate = "Start date",
  EndDate = "End date",
  DateFlexibility = "Date flexibility",
  Guests = "Guests",
}

export enum InquiryModalTypes {
  REOPEN = "reopen",
  CLOSE = "close",
  BOOK = "book"
}

export enum EmailAttachmentContentTypes {
  PDF = "application/pdf",
  DOC = "application/msword",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  XLS = "application/vnd.ms-excel",
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  PPT = "application/vnd.ms-powerpoint",
  PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  MP4 = "video/mp4",
  OGV = "video/ogg",
}

export enum InquiryLoadingProcess {
  BOOK = "book",
  CLOSE = "close",
  STILL_IN_CONTACT = "stillInContact",
  REOPEN = "reopen",
  FILTER = "filterInquiries"
}

export enum ListingStatus {
  DRAFT = "draft",
  PENDING_REVIEW = "pending_review",
  PUBLISHED = "published",
  UNPUBLISHED = "unpublished",
}

export enum ListingStatusLabel {
  DRAFT = "Draft",
  PENDING_REVIEW = "Pending review",
  PUBLISHED = "Published",
  UNPUBLISHED = "Unpublished",
}
