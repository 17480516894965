import { ItemInterface } from "react-sortablejs";
import { SearchImage } from "@/src/type/blaceV2/search/SearchType";

export interface ListingItemFile {
  name: string;
  fileNameCanonical: string;
  type: string;
  contentType: string;
  size: number;
  blobName: number;
  url?: string;
}

export enum ListingItemFileTypes {
  FloorPlan = "floor_plan",
  TearSheet = "tear_sheet",
}

export interface SortablePhotoFile extends ItemInterface {
  id: string;
  originLink?: string;
  imageHash?: string;
  contentType?: string;
  file?: File;
  loading?: boolean;
  failed?: boolean;
}

export enum PriceDurationBE {
  PerDay = "per_day",
  PerHalfDay = "per_half_day",
  PerHour = "per_hour",
  PerPerson = "per_person",
}

export enum PriceDurationFE {
  PerDay = "Per Day",
  PerHalfDay = "Per Half Day",
  PerHour = "Per Hour",
  PerPerson = "Per Person",
}

export interface RoomPhotoFile extends SearchImage {
  loading?: boolean;
  failed?: boolean;
  isSelected?: boolean;
  file?: File;
}

