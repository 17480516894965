import React, { useEffect, useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { BaseButton, BlaceV2QueryConstructorHelper, Log } from "blace-frontend-library";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { BaseIcon } from "@/src/component/base";
import { PaginationComponent } from "@/src/component/partial/Pagination";
import { useToast } from "@/src/component/provider";
import { AddListingModal } from "@/src/component/view/Listings/component/AddListingModal";
import { ListingsContainer } from "@/src/component/view/Listings/component/ListingsContainer";
import { LISTING_MANAGEMENT_TOAST_TTL } from "@/src/const";
import { B2BSearchServiceV2 } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import styles from "./Listings.module.scss";

function Listings() {
  const [listings, setListings] = useState<BlaceV2Type.SearchType.SearchItem[]>([]);
  const [isListingsLoading, setIsListingsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAddListingModalOpen, setIsAddListingModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [totalListingsCount, setTotalListingsCount] = useState(0);
  const [searchParams] = useSearchParams();
  const { setToastMessage } = useToast();

  const fetchingErrorText =
    "Our server encountered an unexpected issue. Please try again in a few minutes.";
  const pageParamKey = "page";
  const size = 20;
  const totalPages = Math.ceil(totalListingsCount / size);
  const orderByDesc = false;

  const listingsContainerClasses = classNames(styles.listingsWrapper, {
    [styles.isLoading]: isListingsLoading,
  });

  const getPaginationFrom = (page: number, size: number) => {
    return page * size - size;
  };

  const orderBy = useMemo(() => {
    return ["substring(title, 1, 64)"];
  }, []);

  const openAddListingModal = () => {
    setIsAddListingModalOpen(true);
  };

  const closeAddListingModal = () => {
    setIsAddListingModalOpen(false);
  };

  const handleCloseAddListingModal = () => {
    closeAddListingModal();
  };

  useEffect(() => {
    const pageFromUrl = Number(searchParams.get(pageParamKey));
    if (pageFromUrl > 0 && page !== pageFromUrl) {
      setPage(pageFromUrl);
    }
  }, [searchParams, page]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsListingsLoading(true);
        setIsError(false);
        const queryConstructor = new BlaceV2QueryConstructorHelper.BlaceV2QueryConstructor(
          size,
          getPaginationFrom(page, size),
        );
        queryConstructor.setOrderBy(orderBy);
        queryConstructor.setOrderDesc(orderByDesc);

        const listingsResponse = await B2BSearchServiceV2.getAllListings(
          queryConstructor ? queryConstructor.getQuery() : null,
        );

        if (listingsResponse.body?.metadata?.statusCode === 200) {
          setListings(listingsResponse.body?.payload.results);
          setTotalListingsCount(listingsResponse.body?.payload.count);
        } else {
          // prettier-ignore
          Log.logToDataDog(
            Log.LogLevel.ERROR,
            "Listings.tsx",
            "fetchListingsError",
            [listingsResponse]
          );
          setIsListingsLoading(false);
          setIsError(true);
          setToastMessage(fetchingErrorText, "error", LISTING_MANAGEMENT_TOAST_TTL);
        }
        setIsListingsLoading(false);
      } catch (error) {
        // prettier-ignore
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "Listings.tsx",
          "fetchListingsError",
          [error],
        );
        setIsListingsLoading(false);
        setIsError(true);
        setToastMessage(fetchingErrorText, "error", LISTING_MANAGEMENT_TOAST_TTL);
      }
    };
    fetchData();
  }, [page, orderBy, orderByDesc, setToastMessage]);

  return (
    <>
      <div className={styles.listingsContainer} data-testid="listingsPage">
        <div className={styles.pageHeaderContainer}>
          <div className={styles.pageTitleContainer}>
            <Typography className={styles.pageTitle}>Manage your listings</Typography>
            <Typography className={styles.pageSubtitle}>
              Here you can view, update, and create new listings
            </Typography>
          </div>
          <BaseButton
            onClick={openAddListingModal}
            startIcon={<BaseIcon iconFileName="layers" iconAlt="layers icon" iconSize={24} />}
            className={styles.addListingButton}
          >
            Add a New Listing
          </BaseButton>
        </div>
        <div className={listingsContainerClasses}>
          <ListingsContainer listings={listings} isLoading={isListingsLoading} isError={isError} />
        </div>
        {totalListingsCount > size && !!listings.length && (
          <div className={styles.paginationWrapper} data-testid="pagination">
            <PaginationComponent
              paginationPagesAmount={totalPages}
              paginationCurrentPage={page}
              withSearchId={false}
              withTopBorder={false}
            />
          </div>
        )}
      </div>
      <AddListingModal isOpen={isAddListingModalOpen} onClose={handleCloseAddListingModal} />
    </>
  );
}

export default Listings;
