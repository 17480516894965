import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import {
  BaseButton,
  FormHelper,
  ImageLogic,
  NumberHelper,
} from "blace-frontend-library";
import cn from "classnames";
import { BaseIcon } from "@/src/component/base";
import { TextareaDescription } from "@/src/component/base/TextareaDescription";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { SaveButton } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/DetailsContent/components/SaveButton";
import RoomContentContext from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/RoomContentContext";
import { RoomPhotosPopup } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/RoomsContent/component/RoomPhotosPopup";
import { SUPPORTED_IMAGE_FORMATS } from "@/src/const";
import { useRoomForm } from "@/src/hook/useRoomForm";
import { FormLogic } from "@/src/model";
import {
  FormRef,
  PriceDurationBE,
  PriceDurationFE,
  RoomPhotoFile,
} from "@/src/type/app";
import { SearchType } from "@/src/type/blaceV2";
import { SearchRoomV2 } from "@/src/type/blaceV2/search/SearchType";
import { uniqueId } from "@/src/util";
import styles from "./RoomForm.module.scss";

interface RoomsFormProps {
  onSaveRoomFormData?: () => void;
}

export enum InputList {
  id = "id",
  RoomName = "name",
  RoomDescription = "description",
  FloorsNumber = "numberOfFloors",
  SquareFootage = "sqFootage",
  Capacity = "maxCapacity",
  Images = "images",
  ShowPricing = "showPricing",
  Price = "pricingValueInCents",
  PriceDuration = "pricingDuration",
}

function RoomForm(props: RoomsFormProps, ref: React.Ref<FormRef>) {
  const { onSaveRoomFormData } = props;
  const { selectedRoom, setRooms, allRooms } =
    RoomContentContext.useRoomContext();
  const [isPhotosPopupOpen, setIsPhotosPopupOpen] = useState(false);
  const [listingPhotos, setListingPhotos] = useState<RoomPhotoFile[]>([]);

  const {
    setIsSaveButtonDisabled,
    isEditRequestSubmitting,
    isSaveButtonDisabled,
    listingItemData,
    listingItemSaveHandler,
  } = useContext(ListingManagementContext) || {};

  const handleSaveRoom = async () => {
    const listingImages = listingPhotos.reduce((acc, photo, order) => {
      if (!photo.file || photo.isSelected) {
        acc.push({
          imageHash: photo.imageHash,
          originLink: photo.originLink,
          order,
          contentType: photo.contentType,
        });
      }
      return acc;
    }, [] as SearchType.SearchImage[]);

    return listingItemSaveHandler
      ? await listingItemSaveHandler({
          rooms: (allRooms as SearchRoomV2[]) ?? [],
          images: listingImages.length
            ? listingImages
            : listingItemData?.images,
        })
      : {};
  };

  const { formik } = useRoomForm({
    roomSaveHandler: handleSaveRoom,
    roomData: selectedRoom,
  });

  useImperativeHandle(ref, () => ({
    submitForm: formik.handleSubmit,
  }));

  const isPhotosError = FormHelper.formikCheckError(formik, InputList.Images);

  const updateRoomField = (
    field: string,
    value?: string | string[] | number | boolean
  ) => {
    setRooms(
      allRooms?.map((room) => {
        if (room.id === selectedRoom?.id) {
          return { ...room, [field]: value || undefined };
        }
        return room;
      })
    );
  };

  const updatePhotoFiles = (selectedImageHashes: string[]) => {
    setListingPhotos((prevPhotoFiles) =>
      prevPhotoFiles.map((photoFile) => {
        return {
          ...photoFile,
          isSelected:
            !!photoFile.imageHash &&
            selectedImageHashes.includes(photoFile.imageHash),
        };
      })
    );
  };

  const handleSelectRoomPhotos = (selectedHashes: string[]) => {
    setIsPhotosPopupOpen(false);
    const roomImageHashesToSave: string[] = [];
    listingPhotos.forEach((photo) => {
      if (photo.imageHash && selectedHashes.includes(photo.imageHash)) {
        roomImageHashesToSave.push(photo.imageHash);
      }
    });
    updatePhotoFiles(selectedHashes);
    formik.setFieldValue(InputList.Images, roomImageHashesToSave);
    updateRoomField(InputList.Images, roomImageHashesToSave);
  };

  // update the save button status
  useEffect(() => {
    const isDisabled = !formik.isValid || !formik.dirty || formik.isSubmitting;
    setIsSaveButtonDisabled && setIsSaveButtonDisabled(isDisabled);
  }, [
    formik.dirty,
    formik.isSubmitting,
    formik.isValid,
    setIsSaveButtonDisabled,
  ]);

  // reset the photos, when we go to another room as we have 1 form and changing room data so next useEffect won't run every time
  useEffect(() => {
    setListingPhotos([]);
  }, [selectedRoom?.id]);

  useEffect(() => {
    if (listingPhotos.length === 0) {
      const existPhotos = listingItemData?.images?.map(({ imageHash }) => ({
        id: uniqueId(),
        imageHash,
        isUploaded: false,
        isSelected: !!imageHash && selectedRoom?.images?.includes(imageHash),
      }));
      existPhotos?.length && setListingPhotos(existPhotos);
    }
  }, [listingItemData, listingPhotos?.length, selectedRoom?.images]);

  return (
    <div className={styles.roomsForm}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formItem}>
          <label
            htmlFor={InputList.RoomName}
            className={cn(styles.inputLabel, styles.required)}
          >
            Room Name
          </label>
          <TextField
            className={styles.textField}
            placeholder="Add Room Title"
            fullWidth
            required
            disabled={isEditRequestSubmitting}
            value={formik.values[InputList.RoomName]}
            onChange={(e) => {
              formik.setFieldValue(InputList.RoomName, e?.target?.value ?? "");
              updateRoomField(InputList.RoomName, e?.target?.value);
            }}
            helperText={FormHelper.formikErrorMessage(
              formik,
              InputList.RoomName
            )}
            error={FormHelper.formikCheckError(formik, InputList.RoomName)}
            id={InputList.RoomName}
          />
        </div>
        <div className={styles.formItem}>
          <label className={cn(styles.inputLabel, styles.required)}>
            Room photos
          </label>

          <label
            htmlFor={InputList.Images}
            className={styles.hiddenInputWrapper}
            style={{
              background: `url(${ImageLogic.getImageUrl(
                formik.values[InputList.Images][0],
                80,
                300
              )})`,
            }}
            onClick={(e) => {
              e.preventDefault();
              if (!formik.isSubmitting) {
                setIsPhotosPopupOpen(true);
              }
            }}
          >
            <Typography className={styles.hiddenInputPlaceholderText}>
              Select Photos
            </Typography>
            <input
              className={styles.hiddenInput}
              id={InputList.Images}
              multiple
              type="file"
              accept={SUPPORTED_IMAGE_FORMATS.join(",")}
            />
          </label>
          {isPhotosError && (
            <FormHelperText error>
              {FormHelper.formikErrorMessage(formik, InputList.Images)}
            </FormHelperText>
          )}
        </div>
        <div className={styles.formItem}>
          <label
            htmlFor={InputList.RoomDescription}
            className={cn(styles.inputLabel, styles.required)}
          >
            Room Description
          </label>
          <TextField
            className={cn(styles.textField, styles.roomDescription)}
            placeholder="Add full description of your Room"
            fullWidth
            required
            disabled={isEditRequestSubmitting}
            value={formik.values[InputList.RoomDescription]}
            onChange={(e) => {
              formik.setFieldValue(
                InputList.RoomDescription,
                e?.target?.value ?? ""
              );
              updateRoomField(InputList.RoomDescription, e?.target?.value);
            }}
            helperText={FormHelper.formikErrorMessage(
              formik,
              InputList.RoomDescription
            )}
            error={FormHelper.formikCheckError(
              formik,
              InputList.RoomDescription
            )}
            multiline
            rows={4}
            id={InputList.RoomDescription}
          />
          <TextareaDescription
            value={formik.values[InputList.RoomDescription]}
            isError={FormHelper.formikCheckError(
              formik,
              InputList.RoomDescription
            )}
          />
        </div>
        <div className={styles.formSection}>
          <div className={styles.formItemsWrapper}>
            <div className={styles.formItem}>
              <label
                htmlFor={InputList.FloorsNumber}
                className={cn(styles.inputLabel, styles.required)}
              >
                Number of Floors
              </label>
              <TextField
                disabled={isEditRequestSubmitting}
                value={NumberHelper.formatFormInputValue(
                  formik.values[InputList.FloorsNumber],
                  true
                )}
                onChange={(e) => {
                  FormLogic.setFormikCleanedNumberValue(
                    e?.target?.value,
                    formik,
                    InputList.FloorsNumber
                  );
                  updateRoomField(
                    InputList.FloorsNumber,
                    +FormLogic.getFormikCleanedNumberValue(e?.target?.value)
                  );
                }}
                helperText={FormHelper.formikErrorMessage(
                  formik,
                  InputList.FloorsNumber
                )}
                error={FormHelper.formikCheckError(
                  formik,
                  InputList.FloorsNumber
                )}
                fullWidth
                className={styles.textField}
                required
                id={InputList.FloorsNumber}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            <div className={styles.formItem}>
              <label
                htmlFor={InputList.SquareFootage}
                className={cn(styles.inputLabel, styles.required)}
              >
                Square Footage
              </label>
              <TextField
                disabled={isEditRequestSubmitting}
                value={NumberHelper.formatFormInputValue(
                  formik.values[InputList.SquareFootage],
                  true
                )}
                onChange={(e) => {
                  FormLogic.setFormikCleanedNumberValue(
                    e?.target?.value,
                    formik,
                    InputList.SquareFootage
                  );
                  updateRoomField(
                    InputList.SquareFootage,
                    +FormLogic.getFormikCleanedNumberValue(e?.target?.value)
                  );
                }}
                helperText={FormHelper.formikErrorMessage(
                  formik,
                  InputList.SquareFootage
                )}
                error={FormHelper.formikCheckError(
                  formik,
                  InputList.SquareFootage
                )}
                fullWidth
                className={styles.textField}
                required
                id={InputList.SquareFootage}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            <div className={styles.formItem}>
              <label
                htmlFor={InputList.Capacity}
                className={cn(styles.inputLabel, styles.required)}
              >
                Max capacity
              </label>
              <TextField
                disabled={isEditRequestSubmitting}
                value={NumberHelper.formatFormInputValue(
                  formik.values[InputList.Capacity],
                  true
                )}
                onChange={(e) => {
                  FormLogic.setFormikCleanedNumberValue(
                    e?.target?.value,
                    formik,
                    InputList.Capacity
                  );
                  updateRoomField(
                    InputList.Capacity,
                    +FormLogic.getFormikCleanedNumberValue(e?.target?.value)
                  );
                }}
                error={FormHelper.formikCheckError(formik, InputList.Capacity)}
                helperText={FormHelper.formikErrorMessage(
                  formik,
                  InputList.Capacity
                )}
                fullWidth
                className={styles.textField}
                id={InputList.Capacity}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
          </div>
        </div>
        <FormControlLabel
          control={
            <Switch
              checked={formik.values[InputList.ShowPricing]}
              onChange={(e) => {
                formik.setFieldValue(InputList.ShowPricing, e.target.checked);
                updateRoomField(InputList.ShowPricing, e.target.checked);
              }}
            />
          }
          className={styles.showPricingControl}
          label={
            <Typography component="div">
              <span className={styles.showPricingControlName}>
                Show pricing
              </span>
              <span className={styles.showPricingControlText}>
                If pricing is turned Off the room will display “Inquire for
                Pricing” in the price section
              </span>
            </Typography>
          }
        />
        <div className={styles.formSection}>
          <div className={styles.formItemsWrapper}>
            <div className={cn(styles.formItem, styles.twoInRow)}>
              <label
                htmlFor={InputList.Price}
                className={cn(styles.inputLabel, {
                  [styles.required]: formik.values[InputList.ShowPricing],
                })}
              >
                Pricing value
              </label>
              <TextField
                disabled={
                  isEditRequestSubmitting ||
                  !formik.values[InputList.ShowPricing]
                }
                value={`$${NumberHelper.formatFormInputValue(
                  formik.values[InputList.Price],
                  true
                )}`}
                onChange={(e) => {
                  FormLogic.setFormikCleanedNumberValue(
                    e?.target?.value.slice(1),
                    formik,
                    InputList.Price
                  );

                  updateRoomField(
                    InputList.Price,
                    +FormLogic.getFormikCleanedNumberValue(e?.target?.value.slice(1)) * 100
                  );
                }}
                helperText={FormHelper.formikErrorMessage(
                  formik,
                  InputList.Price
                )}
                error={FormHelper.formikCheckError(formik, InputList.Price)}
                fullWidth
                className={styles.textField}
                required
                id={InputList.Price}
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                }}
              />
            </div>
            <div className={cn(styles.formItem, styles.twoInRow)}>
              <label
                htmlFor={InputList.PriceDuration}
                className={cn(styles.inputLabel, {
                  [styles.required]: formik.values[InputList.ShowPricing],
                })}
              >
                Pricing duration
              </label>
              <Select
                labelId={InputList.PriceDuration}
                disabled={
                  isEditRequestSubmitting ||
                  !formik.values[InputList.ShowPricing]
                }
                value={formik.values[InputList.PriceDuration]}
                fullWidth
                className={styles.textField}
                error={FormHelper.formikCheckError(
                  formik,
                  InputList.PriceDuration
                )}
                id={InputList.PriceDuration}
                onChange={(e) => {
                  formik.setFieldValue(InputList.PriceDuration, e.target.value);
                  updateRoomField(InputList.PriceDuration, e?.target?.value);
                }}
              >
                <MenuItem value={PriceDurationBE.PerDay}>
                  {PriceDurationFE.PerDay}
                </MenuItem>
                <MenuItem value={PriceDurationBE.PerHalfDay}>
                  {PriceDurationFE.PerHalfDay}
                </MenuItem>
                <MenuItem value={PriceDurationBE.PerHour}>
                  {PriceDurationFE.PerHour}
                </MenuItem>
                <MenuItem value={PriceDurationBE.PerPerson}>
                  {PriceDurationFE.PerPerson}
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </form>
      <div className={styles.formActionButtons}>
        <SaveButton
          isFullWidth
          isSaveButtonLoading={Boolean(isEditRequestSubmitting)}
          isSaveButtonDisabled={Boolean(isSaveButtonDisabled)}
          onSaveDetailsFormData={onSaveRoomFormData}
        />
        <BaseButton
          startIcon={
            <BaseIcon
              iconFileName={"publishIcon"}
              iconAlt="retry icon"
              iconSize={20}
            />
          }
          className={cn(styles.actionButton, {
            [styles.disabled]: true,
          })}
        >
          Publish
        </BaseButton>
      </div>
      {isPhotosPopupOpen && (
        <RoomPhotosPopup
          photoFiles={listingPhotos}
          setPhotoFiles={setListingPhotos}
          selectedPhotos={formik.values[InputList.Images]}
          isOpen={isPhotosPopupOpen}
          handleClose={() => setIsPhotosPopupOpen(false)}
          handleSelectRoomPhotos={handleSelectRoomPhotos}
        />
      )}
    </div>
  );
}

export default forwardRef(RoomForm);
