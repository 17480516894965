import { StringHelper } from "blace-frontend-library";
import { AppSearchFilterType } from "@/src/type";

export function constructSearchTerm(
  queryType?: "full" | "simple",
  query?: string
) {
  if (!query) {
    return "";
  }

  query = escapeSpecialCharacters(StringHelper.trim(query));

  const partialMatchQuery: string = query?.replaceAll(" ", "*");

  return queryType === "full" ? `${partialMatchQuery}* OR ${query}` : query;
}

/**
 * @see https://learn.microsoft.com/en-us/azure/search/query-lucene-syntax#special-characters
 * @param inputString 
 * @returns 
 */
export function escapeSpecialCharacters(inputString: string) {

  return (inputString ?? "").replace(/[-+\\&|!(){}[\]^"~*?:/]/g, "\\$&");
}

export function escapeStringForComparison(inputString: string) {
  return (inputString ?? "").replace("'", "''");
}

/**
 * Construct a filter expression for Search query
 * https://learn.microsoft.com/en-us/azure/search/search-query-odata-filter
 */

/**
 * Construct a filter statement based on a property
 *
 * @param {AppSearchFilterType.AppSearchFilter} filter - the data about the filter
 * @param {string} expression - the expression used to search a property
 * @param {Record<string, any>} data - the data from the filter selection panel
 * @returns
 */
export function constructFilterItem(
  filter: AppSearchFilterType.AppSearchFilter,
  expression: string,
  data: any
): string {
  if (!data?.[filter.dataKey]) {
    return "";
  }

  const propertyData = data?.[filter.dataKey];
  switch (filter?.type ?? "default") {
    case "multi-choice":
      const terms = propertyData.join("|");
      //make sure to set delimiter in search query
      //delimited = 3rd method parameter in search.in
      return `search.in(${expression},'${terms}','|')`;
    case "single-choice":
    case "slider":
      if (
        typeof propertyData === "boolean" ||
        propertyData === "true" ||
        propertyData === "false"
      ) {
        return `${expression} eq ${propertyData}`;
      } else if (typeof propertyData === "string") {
        return `${expression} eq '${escapeStringForComparison(propertyData)}'`;
      }
    case "date-ge":
      const dateGe = new Date(Number(propertyData));
      if (Number.isNaN(dateGe.getTime())) {
        return ""; // broken or unsupported data
      }
      return `${expression} ge ${dateGe.getTime()}`;
    case "date-le":
      const dateLe = new Date(Number(propertyData));
      if (Number.isNaN(dateLe.getTime())) {
        return ""; // broken or unsupported data
      }
      return `${expression} le ${dateLe.getTime()}`;
    case "callback":
      if (!filter.callback) {
        return ""; // callback wasn't defined
      }
      return filter.callback(expression, propertyData);
  }
}

/**
 * Construct a filter statement based on a property
 *
 * @param {string}                                              propertyKey   - the filter key which is currently processed
 * @param {Record<string, any>}                                 filterData    - the data from the filter selection panel
 * @param {Record<string, AppSearchFilterType.AppSearchFilter>} filtersConfig - the configuration for the given filters
 * @returns
 */
export function constructFilterIteration(
    propertyKey: string,
    filterData?: Record<string, any>,
    filtersConfig?: Record<string, AppSearchFilterType.AppSearchFilter>
): string {
  if (!filtersConfig || !filtersConfig?.[propertyKey]) {
    return "";
  }
  if (
    !filterData ||
    !filterData?.[filtersConfig?.[propertyKey]?.dataKey] ||
    (!filterData?.[filtersConfig?.[propertyKey]?.dataKey]?.[0] &&
      typeof filterData?.[filtersConfig?.[propertyKey]?.dataKey] !== "object")
  ) {
    return "";
  }

  const filter = filtersConfig[propertyKey];
  if (typeof filter !== "undefined") {
    switch (filter.propertyType) {
      case "string":
      case "boolean":
      case "object":
        return constructFilterItem(
            filter,
            filter.property,
            filterData
        );
      case "simple-array":
        const expSimpleArray = filter.property.substring(0, 1);
        return constructFilterItem(
            filter,
            expSimpleArray,
            filterData
        );
      case "complex-array":
        const splitComplexArray = filter.property.split("/");
        const rootComplexArray = splitComplexArray[0];
        const rootPropertyAbbrev = rootComplexArray.substring(0, 1);
        const expComplexArray = `${rootPropertyAbbrev}/${splitComplexArray[1]}`;
        return constructFilterItem(
            filter,
            expComplexArray,
            filterData
        );
      case "date":
        return constructFilterItem(
            filter,
            filter.property,
            filterData
        );
    }
  }
  return "";
}

/**
 * Constructs the filter with multiple properties based on filter data
 *
 * @param {Record<string, any>}                                 filterData    - the data from the filter selection panel
 * @param {Record<string, AppSearchFilterType.AppSearchFilter>} filtersConfig - the configuration for the given filters
 * @returns
 */
export function constructFilter(
  filterData?: Record<string, any>,
  filtersConfig?: Record<string, AppSearchFilterType.AppSearchFilter>
): string {
  if (!filterData || (Object.keys(filterData) ?? []).length === 0 || !filtersConfig) {
    return "";
  }

  let filterExpression = "";
  let prefix = false;
  for (const propertyKey of Object.keys(filterData)) {
    const filterIterationExpression = constructFilterIteration(propertyKey, filterData, filtersConfig);
    if (filterIterationExpression.length > 0) {
      filterExpression += `${prefix ? " and " : ""}${filterIterationExpression}`;
      prefix = filterExpression !== "";
    }
  }
  return filterExpression;
}

/**
 * add the delimiter for a multi-choise
 * 
 * @param {string[] | number[]} data - any array of strings of numbers to be serialized as a multi-choice selection
 * @returns {string}
 */
export function constructQueryParamForMultiChoice(
  data: string[] | number[]
): string {
  return (data ?? []).length > 0 ? data.join("*") : "";
}

/**
 * deconstruct query params for filter state
 *
 * @param {string} searchParams - the search params from the url as a string
 * @param {Record<string, AppSearchFilterType.AppSearchFilter>} filtersConfig - the configuration for the given filters
 * @returns {Record<string,any>}
 */
export function deconstructQueryParams(
  searchParams: string,
  filtersConfig?: Record<string, AppSearchFilterType.AppSearchFilter>
): Record<string,any> {
  if (!searchParams || !filtersConfig) {
    return {};
  }

  const filterData: Record<string, any> = {};
  const values = new URLSearchParams(searchParams);
  for (const propertyKey of Object.keys(filtersConfig)) {
    const filter = filtersConfig[propertyKey];
    const data = values.get(filter.dataKey);
    if (data) {
      if (filter.type === "multi-choice") {
        filterData[filter.dataKey] = data.split("*");
      } else {
        filterData[filter.dataKey] = data.replace("+", " ");
      }
    }
  }

  return filterData;
}

/**
 * Converts the V1 floors value to the V2 numeric one
 *
 * @param {string | undefined} floorValue - the value in format "Single Floor", "Multi Level", etc.
 * @returns {string | undefined}
 */
export function legacyFloorsToV2Number(
    floorValue?: string
): string | undefined {
  if (!floorValue) {
    return undefined;
  }

  if ((+floorValue).toString() === floorValue) {
    return floorValue;
  }

  switch (floorValue) {
    case "Single Floor":
      return "1";
    case "Two Floors":
      return "2";
    case "Three Floors":
      return "3";
    case "Four Floors":
      return "4";
    case "Five Floors":
      return "5";
  }

  return undefined;
}
