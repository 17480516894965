import { BaseButton } from "blace-frontend-library";
import cn from "classnames";
import styles from "./SaveButton.module.scss";

interface SaveButtonProps {
  isSaveButtonLoading: boolean;
  isSaveButtonDisabled: boolean;
  isFullWidth?: boolean,
  onSaveDetailsFormData?: () => void;
}

function SaveButton({
  isSaveButtonLoading,
  isSaveButtonDisabled,
  onSaveDetailsFormData,
  isFullWidth=false
}: SaveButtonProps) {

  return (
    <BaseButton
      type="submit"
      loadingColor="inherit"
      loading={isSaveButtonLoading}
      onClick={onSaveDetailsFormData}
      disabled={isSaveButtonDisabled}
      className={cn(styles.detailsSaveButton, {
        [styles.isDisabled]: isSaveButtonDisabled,
        [styles.fullWidth]: isFullWidth,
      })}
      data-testid="save-button"
    >
      Save
    </BaseButton>
  );
}

export default SaveButton;
