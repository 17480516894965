import React, { Dispatch, SetStateAction, useContext } from "react";
import { BaseLoader, useBreakPointDown } from "blace-frontend-library";
import cn from "classnames";
import { BaseIcon } from "@/src/component/base";
import { ListingManagementContext } from "@/src/component/view/ListingManagement/ListingManagementContext";
import { PhotoCoverTag } from "@/src/component/view/ListingManagement/components/MainSection/components/MainSectionContent/PhotosContent/component/PhotoCoverTag";
import { PhotoLogic } from "@/src/model";
import { SortablePhotoFile } from "@/src/type/app";
import styles from "./PhotoSortableList.module.scss";

interface PhotoSortableList {
  photoFiles: SortablePhotoFile[];
  selectedFileIds: string[];
  handleSetPhotoFiles: (files: SortablePhotoFile[]) => void;
  handleDeleteIconClick: (photoIdToDelete: string) => void;
  setSelectedFileIds: Dispatch<SetStateAction<string[]>>;
}

function PhotoSortableList({
  photoFiles,
  selectedFileIds,
  handleDeleteIconClick,
  setSelectedFileIds,
  handleSetPhotoFiles,
}: PhotoSortableList) {
  const isMobile = useBreakPointDown("md");
  const listingManagementContext = useContext(ListingManagementContext);

  const setAsCoverImage = (index: number) => {
    const fileToBeFirst = photoFiles[index];
    const photosToMove = [...photoFiles];
    photosToMove.splice(index, 1);
    photosToMove.unshift(fileToBeFirst);
    listingManagementContext?.setHasUnsavedData(true);
    handleSetPhotoFiles(photosToMove);
  };

  const handlePhotoSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    selectionPhotoId: string
  ) => {
    if (event.target.checked) {
      setSelectedFileIds([...selectedFileIds, selectionPhotoId]);
    } else {
      setSelectedFileIds(
        selectedFileIds.filter((fileId) => fileId !== selectionPhotoId)
      );
    }
  };

  return photoFiles?.map((file, index) => {
    const isFirst = index === 0;
    const isSelected = selectedFileIds.includes(file.id);
    const fileImageUrl = PhotoLogic.getFileImageUrl(file);
    return (
      <div
        key={file.id}
        className={cn(styles.photoWrapper, {
          [styles.selected]: isSelected,
        })}
        data-testid="sortable-image"
      >
        {isMobile && (
          <div className={cn(styles.dotsHandle, "dots-handle")}>
            <BaseIcon
              iconFileName="moveDotsIcon"
              iconAlt="move dots icon"
              iconSize={24}
              className={styles.dotsHandleImage}
            />
          </div>
        )}
        <img
          className={cn(styles.uploadedPhoto, {
            [styles.isLoading]: file.loading,
          })}
          src={fileImageUrl}
          key={`img-${file.id}`}
          alt={`photo ${index + 1}`}
        />
        {file.loading && (
          <BaseLoader
            size={16}
            color="inherit"
            wrapperClassName={styles.imageLoaderWrapper}
          />
        )}
        <PhotoCoverTag
          tagStyles={isFirst ? styles.coverImageTag : styles.setCoverImageTag}
          tagText={isFirst ? "Cover Image" : "Set as Cover Image"}
          tagIconName={isFirst ? "greenStarIcon" : "yellowStarIcon"}
          handleClick={() => setAsCoverImage(index)}
        />
        <div
          className={styles.trashIconContainer}
          onClick={() => handleDeleteIconClick(file.id)}
        >
          <BaseIcon
            iconFileName="trashIcon"
            iconAlt="remove icon"
            iconSize={24}
          />
        </div>
        <div
          className={cn(styles.checkboxWrapper, {
            [styles.isSelected]: isSelected,
          })}
        >
          <input
            type="checkbox"
            checked={isSelected}
            onChange={(e) => handlePhotoSelection(e, file.id)}
          />
        </div>
      </div>
    );
  });
}

export default PhotoSortableList;
