import { useState } from "react";
import { Typography } from "@mui/material";
import { ActivityLogic, BaseButton, useBreakPointUp } from "blace-frontend-library";
import { useSearchParams } from "react-router-dom";
import {
  DASHBOARD_FILTER_PARAM,
  DASHBOARD_FILTERS,
  DASHBOARD_PAGE_PARAM,
  DASHBOARD_SEARCH_ID_PARAM
} from "@/src/component/view/Dashboard/DashboardConstant";
import { SearchItemFilter } from "@/src/component/view/Dashboard/component/LeftContainer/component/Filters/SearchItemFilter";
import { StatusFilter } from "@/src/component/view/Dashboard/component/LeftContainer/component/Filters/StatusFilter";
import { MODAL_AUTO_CLOSE_TIMEOUT } from "@/src/const";
import { SearchLogic } from "@/src/model";
import { uniqueId } from "@/src/util";
import styles from "./FiltersList.module.scss";

interface FiltersListProps {
  handleClosePopperDropDown: () => void;
  handleMobileDialogClose: () => void;
}

function FiltersList({
  handleClosePopperDropDown,
  handleMobileDialogClose
}: FiltersListProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [preFilterState, setPreFilterState] = useState<Record<string, any>>(
    SearchLogic.deconstructQueryParams(searchParams.toString(), DASHBOARD_FILTERS)
  );

  const isDesktop = useBreakPointUp("md");

  function trackFilterUsed (value: string | null, filterKey: string) {
    if (!value) {
      return;
    }

    ActivityLogic.toActivityService({
      action: "filter_bdash",
      actionId: value,
      actionIdType: "dashFilter",
      actionMessage: value,
      data1Type: "filterType",
      data1Value: filterKey,
      data2Type: "value",
      data2Value: value,
      locationInApp: "FiltersList.tsx",
    });
  };

  const handleCloseFiltersWidget = () => {
    setTimeout(() => {
      if (isDesktop) {
        handleClosePopperDropDown();
      } else {
        handleMobileDialogClose();
      }
    }, MODAL_AUTO_CLOSE_TIMEOUT);
  };

  function handleApplyFilters() {
    //TODO: make sure to use search logic here in future iterations
    const filterParams: string[] = Object.values(DASHBOARD_FILTER_PARAM);
    for (const filterParam of filterParams) {
      const thisPreFilterState = preFilterState?.[filterParam];
      if (Array.isArray(thisPreFilterState)) {
        searchParams.set(filterParam, SearchLogic.constructQueryParamForMultiChoice(thisPreFilterState));
      }
      else {
        searchParams.set(filterParam, thisPreFilterState ?? "");
      }
    }
    searchParams.set(DASHBOARD_PAGE_PARAM, "1");
    searchParams.set(DASHBOARD_SEARCH_ID_PARAM, uniqueId());
    //@ts-ignore
    for (const key of searchParams.keys()) {
      trackFilterUsed(searchParams.get(key), key);
    }
    setSearchParams(searchParams);

    handleCloseFiltersWidget();
  }

  function handleClearAllFilters() {
    const filterParams: string[] = Object.values(DASHBOARD_FILTER_PARAM);
    for (const filterParam of filterParams) {
      searchParams.set(filterParam, "");
    }
    searchParams.set(DASHBOARD_PAGE_PARAM, "1");
    searchParams.set(DASHBOARD_SEARCH_ID_PARAM, uniqueId());
    setSearchParams(searchParams);
   
    handleCloseFiltersWidget();
  }

  return (
    <div className={styles.filtersWrapper}>
      <div className={styles.filterItem}>
        <Typography variant="h4" className={styles.filterTitle}>
          Status
        </Typography>
        <StatusFilter
          preFilterState={preFilterState}
          setPreFilterState={setPreFilterState}
        />
      </div>
      <div className={styles.filterItem}>
        <Typography variant="h4" className={styles.filterTitle}>
          Venue or Vendor Name
        </Typography>
        <SearchItemFilter
          preFilterState={preFilterState}
          setPreFilterState={setPreFilterState}
        />
      </div>
      <div className={styles.clearFiltersSection}>
        <BaseButton
          className={styles.clearButton}
          onClick={handleClearAllFilters}
          data-testid="clear-all"
        >
          Clear all filters
        </BaseButton>
        <BaseButton
          className={styles.applyButton}
          onClick={handleApplyFilters}
          data-testid="apply"
        >
          Apply
        </BaseButton>
      </div>
    </div>
  );
}

export default FiltersList;

