import { useEffect, useState } from "react";
import { Fade, Typography } from "@mui/material";
import {
  ActivityLogic,
  AuthLogic,
  BaseButton,
  DashboardLayout,
  ImageLogic,
  Log,
  PriceHelper,
  StringHelper,
  useBreakPointDown
} from "blace-frontend-library";
import { useSearchParams } from "react-router-dom";
import { SuccessMessage } from "@/src/component/view/Dashboard/component/MainContainer/components/SuccessMessage";
import { DASHBOARD_LOGS_REFRESH_TIMEOUT, DASHBOARD_TOAST_FADE_TIMEOUT, DASHBOARD_TOAST_TTL, ENV } from "@/src/const";
import { InquiryLogic, SearchLogic } from "@/src/model";
import { B2BInquiryServiceV2, B2BSearchServiceV2 } from "@/src/service";
import { BlaceB2BType, BlaceV1Type, BlaceV2Type } from "@/src/type";
import { InquiryLoadingProcess } from "@/src/type/blaceV2";
import { uniqueId } from "@/src/util";
import styles from "./Dashboard.module.scss";
import {
  DASHBOARD_FILTERS,
  DASHBOARD_FREE_TEXT_SEARCH_PARAM,
  DASHBOARD_INQUIRY_SELECTED_PARAM,
  DASHBOARD_NAV_DRAWER_PARAM,
  DASHBOARD_PAGE_PARAM,
  DASHBOARD_SEARCH_ID_PARAM,
  DASHBOARD_SORT_PARAM,
  INQUIRY_CLOSED_REASON_EXCHANGE
} from "./DashboardConstant";
import { DashboardContext } from "./DashboardContext";
import { DashboardFallback } from "./component/DashboardFallback";
import { DashboardMobileManager } from "./component/DashboardMobileManager";
import { FAQContainer } from "./component/FAQContainer";
import { LeftContainer } from "./component/LeftContainer";
import { MainContainer } from "./component/MainContainer";
import { RightContainer } from "./component/RightContainer";


type LastInquirySearchQuery = {
  sessionId?: string;
  searchId?: string;
  searchTerm?: string;
  resultsPerPage?: number;
  pageNumber?: number;
  totalResults?: number;
  totalPages?: number;
  appliedFilters?: number;
  searchResults?: BlaceV2Type.InquirySearchType[];
  query?: BlaceV2Type.AzureSearchQueryType.Request;
  hasData?: boolean;
  initialLoad?: boolean;
};

export type FileData = {
  name: string, 
  size: number, 
  contentType: string, 
  blobName: string
}

export type BookingDataInquiry = {
  referralValue: number,
  totalValue: number,
  uploadedPdfData: Array<FileData> | null;
}

export enum DashboardSortParams {
  LastUpdate = "last_update",
  SubmissionDate = "submission_date"
}

export enum DasboardNavDrawerParams {
  Open = "open",
  Closed = "closed"
}

function Dashboard() {
  //constants
  const inquriesPerPageAmount = 20;

  //hooks
  const [searchParams, setSearchParams] = useSearchParams();
  const isMobile = useBreakPointDown("md");
  const isDesktop = useBreakPointDown("md");

  //retrieved data for search / inquiry selection
  const [lastSearch, setLastSearch] = useState<LastInquirySearchQuery>({});
  const [selectedInquiry, setSelectedInquiry] = useState<BlaceV2Type.InquirySearchType | undefined>(undefined);
  const [refreshLogId, setRefreshLogId] = useState<string>("init");
  const [needsUpdateInquiryNumber, setNeedsUpdateInquiryNumber] = useState(0);
  //get filter values
  const [searchCatalogItemFilters, setSearchCatalogItemFilters] = useState<BlaceB2BType.B2BSearchType.B2BSearchAccount[] | undefined>(undefined);
  //current user
  const [currentUser, setCurrentUser] = useState<BlaceV1Type.AuthType.LoginResponseProfile | undefined>(undefined);

  //layout management
  const [isLeftContainerCollapsed, setIsLeftContainerCollapsed] = useState<boolean>(false);
  const [isRightContainerCollapsed, setIsRightContainerCollapsed] = useState<boolean>(false);
  const [isFAQDesktopOpen, setIsFAQDesktopOpen] = useState<boolean>(false);
  const [isFAQMobileOpen, setIsFAQMobileOpen] = useState<boolean>(false);
  const [showFallback, setShowFallback] = useState<"fetching" | "error" | "noEvents" | undefined>("fetching");
  const [loadingProcess, setLoadingProcess] = useState<InquiryLoadingProcess | "">("");

  //close inquiry modal management
  const [isCloseInquiryModalOpen, setIsCloseInquiryModalOpen] = useState(false);

  //reopen inquiry modal management
  const [isReopenInquiryModalOpen, setIsReopenInquiryModalOpen] = useState(false);

  //confirm booking modal management
  const [isConfirmBookingInquiryModalOpen, setIsConfirmBookingInquiryModalOpen] = useState(false);

  //success message: management
  const [inquirySuccessMessage, setInquirySuccessMessage] = useState("");
  const [inquirySuccessMessageFadeIn, setInquirySuccessMessageFadeIn] = useState(false);

  function forceInquiriesListUpdate() {
    setTimeout(() => {
      // the `searchId` change will trigger inquiries list reload
      searchParams.set(DASHBOARD_SEARCH_ID_PARAM, uniqueId());
      setSearchParams(searchParams);
    }, 2000);
  }

  /**
   * when this is executed, a new id will be set triggering the inquiry log to reset
   */
  function refreshLog() {
    setRefreshLogId(uniqueId());
  }

  /**
   * request to still in contact inquiry
  */
  const handleStillInContact = async () => {
    try {
      setLoadingProcess(InquiryLoadingProcess.STILL_IN_CONTACT);
      const putInquiryStillInContactResponse = await B2BInquiryServiceV2.putInquiryStillInContact(
        selectedInquiry?.inquiryId ?? "",
      );
      ActivityLogic.toActivityService({
        action: "stillInContact",
        actionId: selectedInquiry?.inquiryId ?? "",
        actionIdType: "inquiry",
        actionMessage: selectedInquiry?.inquiryId ?? "",
        locationInApp: "Dashboard.tsx",
      });
      if (putInquiryStillInContactResponse?.body?.metadata.status === "success") {
        forceInquiriesListUpdate();

        setTimeout(() => {
          refreshLog();
        }, DASHBOARD_LOGS_REFRESH_TIMEOUT);
      }
      setLoadingProcess("");
    } catch (error) {
      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "Dashboard.tsx",
        "stillInContactInquiryError",
        [error]
      );
      setLoadingProcess("");
    }
  };

  /**
   * request to close inquiry
   */
  async function handleInquiryToClose(closeReason: BlaceB2BType.B2BInquiryType.CloseInquiryOptions) {
    try {
      setLoadingProcess(InquiryLoadingProcess.CLOSE);
      const closedReasonCode: string = INQUIRY_CLOSED_REASON_EXCHANGE?.[closeReason ?? INQUIRY_CLOSED_REASON_EXCHANGE.Other];
      const putInquiryClosedResponse = await B2BInquiryServiceV2.putInquiryClosed(
        selectedInquiry?.inquiryId ?? "",
        closedReasonCode,
        closeReason
      );

      setIsCloseInquiryModalOpen(false);

      ActivityLogic.ga4Tracking(
        "inquiry_closed",
        {
          reason: closeReason,
        },
        false
      );
      ActivityLogic.toActivityService({
        action: "closeInquiry",
        actionId: selectedInquiry?.inquiryId ?? "",
        actionIdType: "inquiry",
        actionMessage: closeReason,
        locationInApp: "Dashboard.tsx",
      });

      if (putInquiryClosedResponse?.body?.metadata.status === "success") {
        forceInquiriesListUpdate();

        setTimeout(() => {
          refreshLog();

          const messagePrefix = selectedInquiry?.eventName  ? ` ${selectedInquiry.eventName}` : "";
          const messageSuffix = selectedInquiry?.clientName ? ` from ${selectedInquiry.clientName}` : "";

          setInquirySuccessMessage(`Inquiry:${messagePrefix}${messageSuffix} has been closed`);
        }, DASHBOARD_LOGS_REFRESH_TIMEOUT);
      }
      setLoadingProcess("");
    } catch (error) {
      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "Dashboard.tsx",
        "closeInquiryError",
        [error]
      );
      setLoadingProcess("");
    }
  }

  /**
   * request to reopen inquiry
   */
  async function handleInquiryToReopen(reopenReason: string) {
    try {
      setLoadingProcess(InquiryLoadingProcess.REOPEN);
      const putInquiryReopenResponse = await B2BInquiryServiceV2.putInquiryReopen(
        selectedInquiry?.inquiryId ?? "",
        reopenReason,
      );

      setIsReopenInquiryModalOpen(false);

      ActivityLogic.toActivityService({
        action: "reopenInquiry",
        actionId: selectedInquiry?.inquiryId ?? "",
        actionIdType: "inquiry",
        actionMessage: selectedInquiry?.inquiryId ?? "",
        data1Type: "message",
        data1Value: reopenReason,
        locationInApp: "Dashboard.tsx",
      });

      if (putInquiryReopenResponse?.body?.metadata.status === "success") {
        forceInquiriesListUpdate();

        setTimeout(() => {
          refreshLog();

          // show reopen inquiry success message
          setInquirySuccessMessage("Inquiry reopened");
          setLastSearch({...lastSearch, searchId: uniqueId()});
        }, DASHBOARD_LOGS_REFRESH_TIMEOUT);
      }
      setLoadingProcess("");
    } catch (error) {
      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "Dashboard.tsx",
        "reopenInquiryError",
        [error]
      );
      setLoadingProcess("");
    }
  }

  /**
   * request to book inquiry
   */
  async function handleInquiryToConfirmBooking(bookingData: BookingDataInquiry) {
    try {
      setLoadingProcess(InquiryLoadingProcess.BOOK);

      const files = bookingData.uploadedPdfData;
      const putInquiryBookedResponse = await B2BInquiryServiceV2.putInquiryBooked(
        selectedInquiry?.inquiryId ?? "",
        PriceHelper.dollarsToCents(bookingData.totalValue),
        PriceHelper.dollarsToCents(bookingData.referralValue),
        files
      );

      setIsConfirmBookingInquiryModalOpen(false);

      ActivityLogic.ga4Tracking(
        "confirmed_booking",
        {
          total: PriceHelper.dollarsToCents(bookingData.totalValue),
          referral: PriceHelper.dollarsToCents(bookingData.referralValue)
        },
        false
      );
      ActivityLogic.toActivityService({
        action: "confirmBooking",
        actionId: selectedInquiry?.inquiryId ?? "",
        actionIdType: "inquiry",
        actionMessage: selectedInquiry?.inquiryId ?? "",
        locationInApp: "Dashboard.tsx",
      });

      if (putInquiryBookedResponse?.body?.metadata.status === "success") {
        forceInquiriesListUpdate();

        setTimeout(() => {
          refreshLog();
        }, DASHBOARD_LOGS_REFRESH_TIMEOUT);
      }
      setLoadingProcess("");
    } catch (error) {
      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "Dashboard.tsx",
        "confirmBookingInquiryError",
        [error]
      );
      setLoadingProcess("");
    }
  }

  /**
   * close the success message
   */
  function handleSuccessMessageClose() {
    setInquirySuccessMessage("");
  }

  /**
   * direct the user to the support form
   */
  function onClickContactSupport() {
    const messageId = uniqueId();
    ActivityLogic.toActivityService({
      action: "contactUs_bdash",
      actionId: messageId,
      actionIdType: "messageId",
      actionMessage: messageId,
      locationInApp: "Dashboard.tsx",
    });
    window.open(`${ENV.ROOT_DOMAIN_URL}/d/en/inquiry/general`, "_blank");
  }

  /**
   * when a user selects an inquiry update select the inquiry and store in state
   *
   * @param inquiry - the inquiry object picked by the user
   */
  function selectInquiry(inquiry?: BlaceV2Type.InquirySearchType) {
    setSelectedInquiry(inquiry ?? undefined);
    if (!inquiry?.inquiryId) {
      searchParams.delete(DASHBOARD_INQUIRY_SELECTED_PARAM);
    } else {
      searchParams.set(DASHBOARD_INQUIRY_SELECTED_PARAM, inquiry.inquiryId);
    }
    setSearchParams(searchParams);
  }

  /**
   * when a user toggle FAQ section
   *
   * @param isOpen - FAQ desktop opening state
   */
  function handleSetIsFAQDesktopOpen(isOpen: boolean) {
    setIsFAQDesktopOpen(isOpen);
    if (isOpen) {
      const trackingId = uniqueId();
      ActivityLogic.toActivityService({
        action: "faqButton",
        actionId: trackingId,
        actionIdType: "click",
        actionMessage: trackingId,
        data1Type: "inquiry",
        data1Value: selectedInquiry?.inquiryId || "",
        locationInApp: "Dashboard.tsx",
      });
    }
  }

  function getSortingType(sortingOption: string | null) {
    if (sortingOption === DashboardSortParams.LastUpdate || !sortingOption) {
      return "lastActivityDate desc, createDate desc";
    } 
    return "createDate desc";
  }

  useEffect(() => {
    if (!inquirySuccessMessage) {
      return;
    }

    setInquirySuccessMessageFadeIn(true);

    const timeoutFadeId = setTimeout(()=> {
      setInquirySuccessMessageFadeIn(false);
    }, DASHBOARD_TOAST_TTL + DASHBOARD_TOAST_FADE_TIMEOUT);

    const timeoutMessageId = setTimeout(()=> {
      setInquirySuccessMessage("");
    }, 2 * DASHBOARD_TOAST_TTL);

    return () => {
      clearInterval(timeoutFadeId);
      clearInterval(timeoutMessageId);
    };
  }, [inquirySuccessMessage]);

  useEffect(() => {
    (async () => {
      const needUpdateNumber = await InquiryLogic.getNeedsUpdateInquiriesNumber();
      setNeedsUpdateInquiryNumber(needUpdateNumber);
    })();
  }, []);
  /**
   * whenever a change occurs, change the inquiry search query and execute
   */
  useEffect(() => {
    if (searchParams.get(DASHBOARD_SEARCH_ID_PARAM) === lastSearch?.searchId) {
      return;
    }

    //TODO: construct the filter string for the query to the backend
    const filters = SearchLogic.deconstructQueryParams(
      searchParams.toString(),
      DASHBOARD_FILTERS
    );
    Log.logToConsoleDebug(
      "Dashboard.tsx",
      "Search params for dashboard query",
      [filters],
      Log.LOG_COLORS.STATE_CHANGE.BG_COLOR,
      Log.LOG_COLORS.STATE_CHANGE.COLOR
    );

    const sortingOption = searchParams.get(DASHBOARD_SORT_PARAM);
  
    const orderByValue = getSortingType(sortingOption);
    (async () => {
      setLoadingProcess(InquiryLoadingProcess.FILTER);
      const lastSearchQuery = lastSearch?.query
        ? {...lastSearch?.query, orderby: orderByValue}
        : null;

      const query = lastSearchQuery ?? {
        count: true,
        search: "",
        searchFields: "clientName, eventName, catalogItem/title",
        queryType: "full",
        searchMode: "all",
        select: "*",
        filter: "",
        top: inquriesPerPageAmount,
        skip: 0,
        orderby: orderByValue,
        sessionId: await ActivityLogic.SESSION_ID(),
      };

      //update any filters from the url parameters
      const page = parseInt(searchParams.get(DASHBOARD_PAGE_PARAM) ?? "1", 10);
      query.skip = (page - 1) * inquriesPerPageAmount;
      query.filter =
        (Object.keys(filters) ?? []).length > 0
          ? SearchLogic.constructFilter(filters, DASHBOARD_FILTERS)
          : undefined;
      query.search = searchParams.get(DASHBOARD_FREE_TEXT_SEARCH_PARAM) ?? "";

      //call the backend for inquiry search results
      //@ts-ignore
      const b2bInquiryQueryResponse = await B2BInquiryServiceV2.postB2BInquiryQuery(query);

      if ((b2bInquiryQueryResponse.body?.payload?.value ?? []).length > 0) {
        //check if the inquiry id exists in the results
        const searchResults: BlaceV2Type.InquirySearchType[] = b2bInquiryQueryResponse.body?.payload?.value ?? [];
        const inquiryCheck: BlaceV2Type.InquirySearchType[] = searchParams.get(DASHBOARD_INQUIRY_SELECTED_PARAM)
          ? searchResults.filter(s => s.inquiryId === searchParams.get(DASHBOARD_INQUIRY_SELECTED_PARAM))
          : [];

        //set the search so that this effect doesn't run again
        //delete any inquiryId if not available in the search results
        const searchId = searchParams.get(DASHBOARD_SEARCH_ID_PARAM) ?? uniqueId();
        if (!searchParams.get(DASHBOARD_SEARCH_ID_PARAM)) {
          searchParams.set(DASHBOARD_SEARCH_ID_PARAM, searchId);
          setSearchParams(searchParams);
        }
        /*
        //this was causing issues with the back button working correctly
        if((inquiryCheck ?? []).length === 0){
          searchParams.delete(DASHBOARD_INQUIRY_SELECTED_PARAM);
        }
        */

        //set the search results
        const totalResults = b2bInquiryQueryResponse.body?.payload["@odata.count"] ?? 0;
        setLastSearch({
          searchId: searchId,
          totalResults,
          resultsPerPage: inquriesPerPageAmount,
          pageNumber: page,
          totalPages: Math.ceil(totalResults / inquriesPerPageAmount),
          searchResults,
          //@ts-ignore
          query,
          hasData: true,
          appliedFilters: Object.keys(filters).length,
          initialLoad: !lastSearch?.initialLoad,
        });

        searchParams.set(DASHBOARD_NAV_DRAWER_PARAM, DasboardNavDrawerParams.Closed);
        setSearchParams(searchParams);
        setShowFallback(undefined);

        //load default inquiry if it is present in the search results
        //inquiry may not always be available as search results expand (i.e more inquiries submitted)
        if ((inquiryCheck ?? []).length > 0) {
          setSelectedInquiry(inquiryCheck[0]);
        } else { 
          isDesktop && setSelectedInquiry(searchResults[0]);
        }
      } else if (lastSearch.hasData || query.filter || query.search) {
        //set the search so that this effect doesn't run again
        const searchId = searchParams.get(DASHBOARD_SEARCH_ID_PARAM) ?? uniqueId();
        if (!searchParams.get(DASHBOARD_SEARCH_ID_PARAM)) {
          searchParams.set(DASHBOARD_SEARCH_ID_PARAM, searchId);
          setSearchParams(searchParams);
        }
        //searchParams.delete(DASHBOARD_INQUIRY_SELECTED_PARAM);

        setLastSearch({
          searchId: searchId,
          totalResults: 0,
          resultsPerPage: inquriesPerPageAmount,
          pageNumber: page,
          totalPages: 0,
          searchResults: [],
          //@ts-ignore
          query,
          hasData: true,
          appliedFilters: Object.keys(filters).length,
          initialLoad: !lastSearch?.initialLoad,
        });
        setShowFallback(undefined);
      } else {
        setShowFallback("noEvents");

        // we should not show the drawer for smaller screens
        // at those screens the drawer overlap the modal message
        const isDrawerShouldBeOpened = window.innerWidth > 1200;
        if (isDrawerShouldBeOpened) {
          searchParams.set(DASHBOARD_NAV_DRAWER_PARAM, DasboardNavDrawerParams.Open);
          setSearchParams(searchParams);
        }
      }

      setLoadingProcess("");

      if (!searchParams.get(DASHBOARD_SORT_PARAM)) {
        searchParams.set(DASHBOARD_SORT_PARAM, DashboardSortParams.LastUpdate);
        setSearchParams(searchParams);
      }
    })();
  }, [searchParams, lastSearch, inquriesPerPageAmount]);

  /**
   * load filters / dropdown prefill values
   */
  useEffect(() => {
    (async () => {
      const getAllSearchItems = await B2BSearchServiceV2.getAllSearchItems();
      if (getAllSearchItems.body?.metadata?.statusCode === 200) {
        setSearchCatalogItemFilters(getAllSearchItems.body?.payload);
      }
    })();
  }, []);

  /**
   * current user info
   */
  useEffect(() => {
    (async () => {
      const currentUser: BlaceV1Type.AuthType.LoginResponseProfile = await AuthLogic.getAuthCurrentUser();
      setCurrentUser(currentUser);
    })();
  }, []);

  /**
   * when no inquiry is selected chose the first one after inquiry search execution
   */
  useEffect(() => {
    if (isMobile) {
      return;
    }

    if (selectedInquiry || !lastSearch?.searchResults || (lastSearch?.searchResults ?? []).length === 0) {
      return;
    }
    selectInquiry(lastSearch.searchResults[0]);
  }, [lastSearch, selectInquiry, isMobile]);

  if (showFallback) {
    return (
      <DashboardFallback
        isFetching={showFallback === "fetching"}
        isNoCreatedIquiries={showFallback === "noEvents"}
        isError={showFallback === "error"}
      />
    );
  }

  return (
    <DashboardContext.Provider
      value={{
        selectedInquiry,
        catalogItemImageUrl: ImageLogic.getMainImageUrl(
          selectedInquiry?.catalogItem
        ),
        clientInitials: StringHelper.getInitialLetters(selectedInquiry?.clientName || ""),
        searchCatalogItemFilters,
        refreshLogId,
        refreshLog,
        closeInquiry: handleInquiryToClose,
        reopenInquiry: handleInquiryToReopen,
        needsUpdateInquiryNumber,
        loadingProcess: loadingProcess,
        lastSearchId: lastSearch.searchId || "",
        currentUser: currentUser,
      }}
    >
      <div className={styles.container} data-testid="dashboard">
        {inquirySuccessMessage && <Fade in={inquirySuccessMessageFadeIn} timeout={DASHBOARD_TOAST_FADE_TIMEOUT}>
          <div className={styles.successMessageWrapper}>
            <SuccessMessage severity="success">
              <>
                <Typography variant="h5">{inquirySuccessMessage}</Typography>
                <BaseButton
                  onClick={handleSuccessMessageClose}
                  className={styles.successMessageCloseButton}
                >
                  Ok
                </BaseButton>
              </>
            </SuccessMessage>
          </div>
        </Fade>}
        <DashboardLayout
          showFAQButton={true}
          isFAQMobileOpen={isFAQMobileOpen}
          isFAQDesktopOpen={isFAQDesktopOpen}
          isInquirySelected={typeof selectedInquiry?.inquiryId === "string"}
          isLeftContainerCollapsed={isLeftContainerCollapsed}
          isRightContainerCollapsed={isRightContainerCollapsed}
          setSelectedInquiry={setSelectedInquiry}
          setIsFAQDesktopOpen={handleSetIsFAQDesktopOpen}
          setIsFAQMobileOpen={setIsFAQMobileOpen}
          setIsLeftContainerCollapsed={setIsLeftContainerCollapsed}
          setIsRightContainerCollapsed={setIsRightContainerCollapsed}
          onClickContactSupport={onClickContactSupport}
          LeftContainer={
            <LeftContainer
              paginationPagesAmount={lastSearch?.totalPages ?? 0}
              paginationCurrentPage={lastSearch?.pageNumber ?? 0}
              appliedFilters={lastSearch?.appliedFilters ?? 0}
              inquriesList={lastSearch.searchResults ?? []}
              totalResults={lastSearch.totalResults ?? 0}
              isLeftContainerCollapsed={isLeftContainerCollapsed}
              selectedInquiry={selectedInquiry}
              setSelectedInquiry={selectInquiry}
              setIsLeftContainerCollapsed={setIsLeftContainerCollapsed}
            />
          }
          DashboardMobileManager={
            <DashboardMobileManager setIsFAQMobileOpen={setIsFAQMobileOpen} onClickHandlerContactSupport={onClickContactSupport}/>
          }
          MainContainer={
            <MainContainer
              selectedInquiry={selectedInquiry}
              isFAQDesktopOpen={isFAQDesktopOpen}
              isCloseInquiryModalOpen={isCloseInquiryModalOpen}
              isReopenInquiryModalOpen={isReopenInquiryModalOpen}
              isConfirmBookingInquiryModalOpen={isConfirmBookingInquiryModalOpen}
              isLeftContainerCollapsed={isLeftContainerCollapsed}
              isRightContainerCollapsed={isRightContainerCollapsed}
              initialLoad={lastSearch?.initialLoad ?? true}
              setIsLeftContainerCollapsed={setIsLeftContainerCollapsed}
              setIsRightContainerCollapsed={setIsRightContainerCollapsed}
              setIsCloseInquiryModalOpen={setIsCloseInquiryModalOpen}
              setIsReopenInquiryModalOpen={setIsReopenInquiryModalOpen}
              setIsConfirmBookingInquiryModalOpen={setIsConfirmBookingInquiryModalOpen}
              setSelectedInquiry={selectInquiry}
              handleInquiryToConfirmBooking={handleInquiryToConfirmBooking}
              handleStillInContact={handleStillInContact}
            />
          }
          RightContainer={
            <RightContainer
              inquiry={selectedInquiry}
              isConfirmBookingInquiryModalOpen={isConfirmBookingInquiryModalOpen}
              isCloseInquiryModalOpen={isCloseInquiryModalOpen}
              isReopenInquiryModalOpen={isReopenInquiryModalOpen}
              isRightContainerCollapsed={isRightContainerCollapsed}
              setIsRightContainerCollapsed={setIsRightContainerCollapsed}
              setIsCloseInquiryModalOpen={setIsCloseInquiryModalOpen}
              setIsConfirmBookingInquiryModalOpen={setIsConfirmBookingInquiryModalOpen}
              setIsReopenInquiryModalOpen={setIsReopenInquiryModalOpen}
            />
          }
          DrawerFAQContainer={
            <FAQContainer/>
          }
        />
      </div>
    </DashboardContext.Provider>
  );
}

export default Dashboard;
