import { differenceInDays } from "date-fns";

// TODO: move it to the blace-library ?
export const isDateLaterThanDaysAgo = (
  dateTimestamp: number,
  daysAgo: number
) => {
  const daysDifference = differenceInDays(new Date(), new Date(dateTimestamp));
  return daysDifference >= daysAgo;
};
