import { List } from "@mui/material";
import { CategoryItem } from "@/src/component/view/ListingManagement/ListingManagement";
import styles from "./MainSectionStepper.module.scss";
import { ItemStep } from "./components/ItemStep";

interface MainSectionStepperProps {
  categoriesList: CategoryItem[];
}

function MainSectionStepper({ categoriesList }: MainSectionStepperProps) {
  return (
    <List className={styles.stepperWrapper}>
      {categoriesList.map(({ id, category, completed }) => (
        <ItemStep id={id} category={category} completed={completed} key={id} />
      ))}
    </List>
  );
}

export default MainSectionStepper;
