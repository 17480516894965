import React, { useMemo } from "react";
import { Typography } from "@mui/material";
import { ToastMessage } from "blace-frontend-library";
import styles from "./ListingToastMessage.module.scss";

interface ListingToastMessageProps {
  wasUpdateError: boolean;
  wasUpdateSuccess: boolean;
}

enum TextMessages {
  Error = "An error occurred while updating the Listing. Please try again later.",
  Success = "The Listing has been updated successfully.",
}

function ListingToastMessage({
  wasUpdateError,
  wasUpdateSuccess,
}: ListingToastMessageProps) {
  const textMessage = useMemo(() => {
    if (wasUpdateSuccess) {
      return TextMessages.Success;
    }

    if (wasUpdateError) {
      return TextMessages.Error;
    }

    return null;
  }, [wasUpdateSuccess, wasUpdateError]);

  const severityValue = useMemo(() => {
    if (wasUpdateSuccess) {
      return "success";
    }

    if (wasUpdateError) {
      return "error";
    }

    return null;
  }, [wasUpdateSuccess, wasUpdateError]);

  if (!severityValue || !textMessage) {
    return <></>;
  }

  return (
    <div className={styles.bannerSticky}>
      <ToastMessage severity={severityValue}>
        <Typography variant="h5">{textMessage}</Typography>
      </ToastMessage>
    </div>
  );
}

export default React.memo(ListingToastMessage);
