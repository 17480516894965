import { useCallback, useEffect, useState } from "react";
import {
  ImageLogic,
  Log,
  useBreakPointDown,
  useBreakPointUp,
} from "blace-frontend-library";
import { useNavigate, useSearchParams } from "react-router-dom";
import { BaseInfoModal } from "@/src/component/base";
import { useToast } from "@/src/component/provider";
import {
  LISTING_MANAGEMENT_TOAST_TTL,
  LISTING_NOT_FOUND_MESSAGE,
  SERVER_ERROR_MESSAGE,
  UNSAVED_CHANGES_WARNING_TEXT,
} from "@/src/const";
import { useListingManagerPageInfo } from "@/src/hook";
import { B2BSearchServiceV2 } from "@/src/service";
import { BlaceV2Type } from "@/src/type";
import { ListingItemFile } from "@/src/type/app";
import { ListingStatus, SearchDataTypes, SearchType } from "@/src/type/blaceV2";
import styles from "./ListingManagement.module.scss";
import { ListingManagementContext } from "./ListingManagementContext";
import { FallbackListingManager } from "./components/FallbackListingManager";
import { LeftSidebar } from "./components/LeftSidebar";
import { ListingToastMessage } from "./components/ListingToastMessage";
import { MainSection } from "./components/MainSection";

export enum ListingCategories {
  Details = "details",
  Photos = "photos",
  Pricing = "pricing",
  Rooms = "rooms",
  Tags = "tags",
  Contacts = "contacts",
}

export const SELECTED_CATEGORY_PARAM = "category";

export interface CategoryItem {
  id: number;
  category: ListingCategories;
  completed: boolean;
}

export interface ListingItemEditableData {
  searchId?: string;
  dataType?: SearchType.SearchDataType;
  status?: ListingStatus;
  title?: string;
  description?: string;
  capacity?: {
    seated?: number;
    standing?: number;
    theater?: number;
    max?: number;
  };
  dimensions?: {
    ceilingHeight?: number;
    numberOfFloors?: number;
    sqFootage?: number;
  };
  locations?: BlaceV2Type.SearchType.SearchLocation[];
  files?: ListingItemFile[];
  images?: BlaceV2Type.SearchType.SearchImage[];
  rooms?: SearchType.SearchRoomV2[];
}

const initialCategoriesList: CategoryItem[] = [
  { id: 1, category: ListingCategories.Details, completed: false },
  { id: 2, category: ListingCategories.Photos, completed: false },
  { id: 3, category: ListingCategories.Pricing, completed: false },
  { id: 4, category: ListingCategories.Rooms, completed: false },
  { id: 5, category: ListingCategories.Tags, completed: false },
  { id: 6, category: ListingCategories.Contacts, completed: false },
];

function ListingManagement() {
  const [listingItemData, setListingItemData] = useState<
    undefined | ListingItemEditableData
  >(undefined);
  const [listingItemMainImageUrl, setListingItemMainImageUrl] =
    useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [categoriesList, setCategoriesList] = useState<CategoryItem[]>(
    initialCategoriesList
  );
  const [listingItemWarningText, setListingItemWarningText] =
    useState<string>("");

  const [categorySelected, setCategorySelected] = useState<
    ListingCategories | undefined
  >(undefined);
  const [wasUpdateItemError, setWasUpdateItemError] = useState(false);
  const [wasUpdateItemSuccess, setWasUpdateItemSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditRequestSubmitting, setIsEditRequestSubmitting] = useState(false);
  const [hasUnsavedData, setHasUnsavedData] = useState(false);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

  const { searchId: requestSearchId, searchDataType } = useListingManagerPageInfo();
  const { setToastMessage } = useToast();

  const navigate = useNavigate();

  const SELECTED_CATEGORY_PARAM = "category";

  const isMobile = useBreakPointDown("md");
  const isDesktop = useBreakPointUp("md");

  const isLeftSideVisible = isDesktop || (isMobile && !categorySelected);

  //todo - replace by data change logic in next iteration
  const isPublishButtonDisabled = true;

  const goBackHandler = useCallback(() => {
    navigate("/listings");
  }, [navigate]);

  const updateListCategories = useCallback((
    list: CategoryItem[],
    validation: Record<string, boolean>
  ): CategoryItem[] => {
    return list.map((item) => ({
      ...item,
      completed: validation[item.category] ?? item.completed,
    }));
  }, []);

  const listingItemSaveHandler = useCallback(async (
    formValuesData: ListingItemEditableData,
    showToastMessage: boolean = true
  ): Promise<Record<string, string>> => {
    setIsEditRequestSubmitting(true);
    try {
      let updatedListingItemResponse;
      if (requestSearchId) {
        updatedListingItemResponse = await B2BSearchServiceV2.patchSearchItem(
            requestSearchId,
            formValuesData,
        );
      } else {
        updatedListingItemResponse = await B2BSearchServiceV2.postSearchItem(
            {...formValuesData, dataType: searchDataType}
        );
      }

      if (updatedListingItemResponse.status >= 200 && updatedListingItemResponse.status < 300) {
        const {
          searchId,
          status,
          title,
          description,
          capacity,
          dimensions,
          locations,
          files,
          images,
          rooms,
          categoriesValidation,
        } = updatedListingItemResponse.body.payload;

        const editedItemData = {
          searchId,
          status,
          title,
          description,
          capacity,
          dimensions,
          locations,
          files,
          images,
          rooms,
        };

        const updatedCategoriesList = updateListCategories(
          initialCategoriesList,
          categoriesValidation
        );
        setCategoriesList(updatedCategoriesList);
        setListingItemData(editedItemData);

        setWasUpdateItemError(false);
        setIsEditRequestSubmitting(false);
        showToastMessage && setWasUpdateItemSuccess(true);

        setHasUnsavedData(false);

        if (!requestSearchId) {
          // we need timeout to give "Unsaved changes" logic understand that flag changed to `false`
          setTimeout(() => {
            navigate(`/listings/${searchDataType}/${searchId}`);
          }, 200);
        }

      } else if (updatedListingItemResponse.status === 400) {
        const errorResponse = JSON.parse(updatedListingItemResponse.error);

        showToastMessage && setWasUpdateItemError(true);
        setIsEditRequestSubmitting(false);
        setWasUpdateItemSuccess(false);

        return errorResponse?.payload?.errors ?? {};
      } else {
        showToastMessage && setWasUpdateItemError(true);
        setIsEditRequestSubmitting(false);
        setWasUpdateItemSuccess(false);

        Log.logToDataDog(
            Log.LogLevel.ERROR,
            "ListingManagement.tsx",
            "pathListingItemError",
            [updatedListingItemResponse]
        );
      }
    } catch (error) {
      showToastMessage && setWasUpdateItemError(true);
      setIsEditRequestSubmitting(false);
      setWasUpdateItemSuccess(false);

      Log.logToDataDog(
        Log.LogLevel.ERROR,
        "ListingManagement.tsx",
        "pathListingItemError",
        [error]
      );
    }

    return {};
  }, [
    navigate,
    requestSearchId,
    searchDataType,
    setIsEditRequestSubmitting,
    updateListCategories,
    setCategoriesList,
    setListingItemData,
    setWasUpdateItemError,
    setWasUpdateItemSuccess,
    setHasUnsavedData,
  ]);

  const onPublishHandler = () => {
    //todo - call api with necessary data
  };

  const categorySelectionHandler = (category: ListingCategories) => {
    if (
      hasUnsavedData &&
      window &&
      !window.confirm(UNSAVED_CHANGES_WARNING_TEXT)
    ) {
      return;
    }

    if (!requestSearchId) {
      setListingItemWarningText(
        "Please add and save a listing name before moving on"
      );
      return;
    }

    searchParams.set(SELECTED_CATEGORY_PARAM, `${category?.toLowerCase()}`);
    setSearchParams(searchParams);
  };

  const getCategoryFromUrl = useCallback((): ListingCategories => {
    const categoryFromUrl = searchParams.get(SELECTED_CATEGORY_PARAM);

    const listingCategories = Object.values(ListingCategories).map((c) =>
      c.toLowerCase()
    );

    const currentCategory = listingCategories.includes(
      categoryFromUrl?.toLowerCase() || ""
    )
      ? categoryFromUrl
      : undefined;

    return currentCategory as ListingCategories;
  }, [searchParams]);

  useEffect(() => {
    if (
      !listingItemData ||
      !listingItemData.images ||
      !listingItemData.images.length
    ) {
      setListingItemMainImageUrl("");
    }

    const fileImageUrl = ImageLogic.getMainImageUrl(listingItemData);
    setListingItemMainImageUrl(fileImageUrl || "");
  }, [listingItemData]);

  useEffect(() => {
    if (wasUpdateItemSuccess || wasUpdateItemError) {
      setTimeout(() => {
        setWasUpdateItemSuccess(false);
        setWasUpdateItemError(false);
      }, LISTING_MANAGEMENT_TOAST_TTL);
    }
  }, [wasUpdateItemSuccess, wasUpdateItemError]);

  useEffect(() => {
    const defaultSelectedCategory: ListingCategories | undefined = isDesktop
      ? ListingCategories.Details
      : undefined;
    const newCategory = getCategoryFromUrl() ?? defaultSelectedCategory;

    if (newCategory !== categorySelected) {
      setCategorySelected(newCategory);
    }
  }, [isDesktop, searchParams, categorySelected, getCategoryFromUrl]);

  // initial page load
  useEffect(() => {
    window.scrollTo(0, 0);

    // it's new item creation, not edit existing one
    if (!requestSearchId) {
      setIsLoading(false);

      return;
    }

    // temporal, till we'll implement the Vendor
    if (searchDataType !== SearchDataTypes.Venue) {
      setIsLoading(false);

      return;
    }

    const fetchListingItem = async () => {
      try {
        const listingItemDataResponse =
          await B2BSearchServiceV2.getSearchItem(requestSearchId);

        setIsLoading(false);
        if (listingItemDataResponse.status === 200) {
          const {
            searchId,
            status,
            title,
            description,
            capacity,
            dimensions,
            locations,
            categoriesValidation,
            files,
            images,
            rooms,
          } = listingItemDataResponse.body.payload;

          const editableItemData = {
            searchId,
            status,
            title,
            description,
            capacity,
            dimensions,
            locations,
            files,
            images,
            rooms,
          };

          const updatedCategoriesList = updateListCategories(
            initialCategoriesList,
            categoriesValidation
          );

          setCategoriesList(updatedCategoriesList);
          setListingItemData(editableItemData);
        } else {
          const errorMessage =
            listingItemDataResponse.status === 404
              ? LISTING_NOT_FOUND_MESSAGE
              : SERVER_ERROR_MESSAGE;
          setToastMessage(
            errorMessage,
            "error",
            LISTING_MANAGEMENT_TOAST_TTL
          );

          Log.logToDataDog(
            Log.LogLevel.ERROR,
            "ListingManagement.tsx",
            "getListingItemError",
            [listingItemDataResponse]
          );

          goBackHandler();
        }
      } catch (error) {
        setToastMessage(
          SERVER_ERROR_MESSAGE,
          "error",
          LISTING_MANAGEMENT_TOAST_TTL
        );
        Log.logToDataDog(
          Log.LogLevel.ERROR,
          "ListingManagement.tsx",
          "fetchListingData",
          [error]
        );

        goBackHandler();
      }
    };

    fetchListingItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <FallbackListingManager isLoading={isLoading} />;
  }

  return (
    <ListingManagementContext.Provider
      value={{
        requestSearchId,
        listingItemData,
        listingItemMainImageUrl,
        setListingItemMainImageUrl,
        categorySelected,
        categorySelectionHandler,
        setListingItemWarningText,
        hasUnsavedData,
        setHasUnsavedData,
        isSaveButtonDisabled,
        setIsSaveButtonDisabled,
        isEditRequestSubmitting,
        listingItemSaveHandler,
      }}
    >
      <ListingToastMessage
        wasUpdateError={wasUpdateItemError}
        wasUpdateSuccess={wasUpdateItemSuccess}
      />
      <div className={styles.listingManagementContainer}>
        {isLeftSideVisible && (
          <div className={styles.leftSidebarWrapper}>
            <LeftSidebar
              categoriesList={categoriesList}
              isPublishButtonDisabled={isPublishButtonDisabled}
              goBackHandler={goBackHandler}
              onPublishHandler={onPublishHandler}
            />
          </div>
        )}
        <div className={styles.mainSectionWrapper}>
          <MainSection categoriesList={categoriesList} />
        </div>
      </div>
      <BaseInfoModal
        infoText={listingItemWarningText}
        isOpen={Boolean(listingItemWarningText)}
        handleClose={() => setListingItemWarningText("")}
      />
    </ListingManagementContext.Provider>
  );
}

export default ListingManagement;
