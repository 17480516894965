import { ListingItemFile, ListingItemFileTypes } from "@/src/type/app";

type ListingItemFilesManagerType = {
  setNewFile: (
      listingItemFiles: ListingItemFile[],
      setListingItemFiles: (files: ListingItemFile[]) => void,
      newListingItemFile: ListingItemFile | null,
      listingItemFileTypeToRemove?: ListingItemFileTypes
  ) => void;
  getFile: (
      listingItemFiles: ListingItemFile[],
      fileType: ListingItemFileTypes
  ) => ListingItemFile | null;
};

export function useListingItemFilesManager(): ListingItemFilesManagerType {
  return {
    setNewFile: (
        listingItemFiles: ListingItemFile[],
        setListingItemFiles: (files: ListingItemFile[]) => void,
        newListingItemFile: ListingItemFile | null,
        listingItemFileTypeToRemove?: ListingItemFileTypes
    ): void => {
      if (!setListingItemFiles) {
        return;
      }

      if (!listingItemFiles.length) {
        if (!newListingItemFile) {
          return;
        }

        const files: ListingItemFile[] = [];

        files.push(newListingItemFile);
        setListingItemFiles(files);

        return;
      }

      if (!newListingItemFile && !listingItemFileTypeToRemove) {
        // it's unclear what to remove
        return;
      }

      const files: ListingItemFile[] = [...listingItemFiles];

      const fileTypeExistIndex = files.findIndex(
          file => file.type === (newListingItemFile?.type ?? listingItemFileTypeToRemove)
      );
      if (newListingItemFile) {
        if (fileTypeExistIndex !== -1) {
          files[fileTypeExistIndex] = newListingItemFile;
        } else {
          files.push(newListingItemFile);
        }
      } else if (fileTypeExistIndex !== -1) {
        files.splice(fileTypeExistIndex, 1);
      } else {
        // the type of file to remove not exists
      }
      setListingItemFiles(files);
    },

    getFile: (listingItemFiles, fileType): ListingItemFile | null => {
      if (!listingItemFiles.length) {
        return null;
      }

      const fileTypeExistIndex = listingItemFiles.findIndex(
          file => file.type === fileType
      );

      return fileTypeExistIndex === -1
          ? null
          : listingItemFiles[fileTypeExistIndex];
    },
  };
}
